import React from "react";
import { Spinner, Stack } from "react-bootstrap";

const Loader = ({ fullScreen = true, bg = "secondary" }) => {
  return (
    <Stack
      className={`bg-${bg} align-items-center justify-content-center ${
        fullScreen ? "vh-100 vw-100 position-absolute" : "w-100 h-100 p-4"
      }`}
    >
      <Spinner />
    </Stack>
  );
};

export default Loader;
