import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Col, FormControl, Image, Row, Stack } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useReactToPrint } from "react-to-print";
import { useConfirmDelete } from "../../imports/hooks";
import { LogoIcon } from "../../imports/images";

const ToolBar = ({
  children,
  data = [],
  filters = [],
  dataKey = "",
  addText = "Add New",
  canCreate = true,
  canDelete = false,
  canPrint = true,
  canRefresh = true,
  printRef = null,
  canExport = false,
  onSearch = () => {},
  onAddClick = () => {},
  deleteAllProgress = false,
  deleteAllHandler = () => {},
  title = "Admin Panel Print",
  subtitle = "Sierraflixx Incorporation",
}) => {
  const ref = useRef();
  const client = useQueryClient();
  const [resData, setResData] = useState([]);
  const { dispatch, verb } = useConfirmDelete();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  function handleSearch({ target }) {
    let filtered = data;

    if (target.value?.length > 0) {
      filtered = data?.filter
        ? data.filter(
            (d) =>
              filters.filter(
                (filter) =>
                  d[filter]
                    ?.toString()
                    ?.toLowerCase()
                    ?.indexOf(target.value.toString().toLowerCase()) > -1
              ).length > 0
          )
        : [];
    }

    onSearch(filtered);
    setResData(filtered);
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        verb: "all",
        title: title,
        ref: resData,
        name: title.toLowerCase(),
        onDelete: deleteAllHandler,
      },
    });
  }

  function handleExport() {}

  useEffect(() => {
    if (data && !resData) {
      setResData(data);
    }
  }, [data, resData]);

  return (
    <>
      <Row className="justify-content-between align-items-center py-2 gy-3">
        <Col md>
          <Stack direction="horizontal" gap={2} className="align-items-center">
            {children}
            {canRefresh && (
              <Button
                size="sm"
                variant="info"
                onClick={() => client.invalidateQueries(dataKey)}
              >
                Refresh
              </Button>
            )}
            {canCreate && (
              <Button onClick={onAddClick} variant="success" size="sm">
                {addText}
              </Button>
            )}

            {canDelete && (
              <Button
                onClick={handleDelete}
                disabled={deleteAllProgress}
                variant="danger"
                size="sm"
              >
                {deleteAllProgress && verb === "all"
                  ? "Deleting..."
                  : "Delete All"}
              </Button>
            )}
            {canPrint && (
              <Button
                size="sm"
                variant="secondary"
                onClick={handlePrint}
                disabled={resData?.length === undefined}
              >
                Print Record
              </Button>
            )}
            {canExport && (
              <Button onClick={handleExport} variant="warning" size="sm">
                Export XL
              </Button>
            )}
          </Stack>
        </Col>

        <Col md={4}>
          <FormControl
            onChange={handleSearch}
            size="sm"
            placeholder="Search..."
            type="search"
            className="bg-white text-dark"
          />
        </Col>
      </Row>

      <div className="d-none">
        <div ref={ref} className="p-5">
          {title && (
            <Stack
              direction="horizontal"
              className="justify-content-between align-items-start"
            >
              <div className="text-dark">
                <h4 className="fw-bold m-0">{title}</h4>
                <p className="mb-4 text-primary">{subtitle}</p>
              </div>
              <Image
                src={LogoIcon}
                width={40}
                height={40}
                className="rounded"
              />
            </Stack>
          )}
          {printRef && printRef?.current && (
            <div
              dangerouslySetInnerHTML={{ __html: printRef.current.innerHTML }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ToolBar;
