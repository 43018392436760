import React, { useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import { useConfirmDelete, useDevices } from "../../imports/hooks";
import { ImageSelect } from "../../imports/components";
import SubmitButton from "../custom/SubmitButton";

const args = {
  device: {},
  show: false,
  hide: () => {},
};

const AddEditDevice = (props = { ...args }) => {
  const ref = useRef();
  const { dispatch } = useConfirmDelete();
  const { device, hide, show } = { ...args, ...props };

  const { create, update, validated, processing, state } = useDevices({
    id: device?.id,
    onSuccess,
  });

  function onSuccess(res) {
    if (res?.success && !device?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        onHide: hide,
        path: "devices",
        key: "devices",
        value: { id: device?.id, name: device?.name },
      },
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {device?.id ? "Edit" : "Add"} Device
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={device?.id ? update : create}
        >
          <Stack gap={3} className="mb-3">
            <Row className="gx-0 align-items-center">
              <Col>
                <ImageSelect name="image" title="image" src={device?.image} />
              </Col>
              <Col className="col-9">
                <Form.FloatingLabel label="Device name">
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Device name"
                    className="text-bg-light"
                    defaultValue={device?.name}
                    required
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a device name
                  </Form.Control.Feedback>
                </Form.FloatingLabel>
              </Col>
            </Row>

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {device?.id ? "Update" : "Create"}
              </SubmitButton>

              {device?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditDevice;
