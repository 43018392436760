import React from "react";
import { Alert, Card, Col, Image, Row, Form, Stack } from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import { Link, SubmitButton } from "../imports/components";
import { useAuth, usePost } from "../imports/hooks";
import { Logo, LogoIcon } from "../imports/images";

function Login() {
  const { authenticated, dispatch } = useAuth();
  const { post, state, isValidated, processing } = usePost("admins/login", {
    replace: true,
    errorInterval: 7000,
    toggleOnSuccess: false,
    onSuccess: (res) => {
      if (res.success) {
        dispatch({
          type: "LOGIN",
          payload: res.data,
        });

        window.location.href = "/admin";
      }

      return !res.success;
    },
  });

  return authenticated ? (
    <Navigate to="/admin" replace={true} state={null} />
  ) : (
    <Col xl={8} lg={10} md={7}>
      <Card className="login-card shadow border-0">
        <Row className="g-0 justify-content-center">
          <Col className="col-6 d-none d-lg-inline text-bg-light rounded-start">
            <Stack className="justify-content-center h-100 align-items-center px-4">
              <Image src={LogoIcon} width={126} />
              <Image src={Logo} width={140} />
              <p className="mt-2 text-justify">
                Our admin panel is dedicated to only our platform admins. If
                your are here by mistake, kindly click the link below to return
                back to the streaming platform or proceed if you are one.
              </p>
              <NavLink
                href="https://sierraflixx.net"
                className="text-primary hover-underline"
              >
                Return to streaming platform
              </NavLink>
            </Stack>
          </Col>
          <Col className="bg-white">
            <Card.Body className="px-4 py-5">
              <Stack className="align-items-center justify-content-center">
                <div className="text-center">
                  <h2 className="fw-bolder text-primary">LOGIN</h2>
                  <p className="m-0">Provide credentials to continue</p>
                </div>
                <Form
                  noValidate
                  validated={isValidated}
                  className="w-100 mt-4 mb-2"
                  onSubmit={post}
                >
                  <Stack gap={2} className="mb-4">
                    <Form.FloatingLabel label="Email or phone">
                      <Form.Control
                        name="email_or_phone"
                        type="text"
                        placeholder="Email or phone"
                        className="text-bg-light"
                        required
                        autoFocus
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email address or phone number
                      </Form.Control.Feedback>
                    </Form.FloatingLabel>

                    <Form.FloatingLabel label="Password">
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="Password"
                        className="text-bg-light"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a password
                      </Form.Control.Feedback>
                    </Form.FloatingLabel>

                    <Stack
                      direction="horizontal"
                      className="justify-content-end"
                    >
                      <Link href="/forgot-password" className="text-info">
                        Forgot password?
                      </Link>
                    </Stack>

                    {state && <Alert variant="danger">{state.message}</Alert>}
                  </Stack>

                  <SubmitButton
                    isProcessing={processing}
                    size="lg"
                    className="w-100"
                  >
                    LOGIN
                  </SubmitButton>
                </Form>
                <Stack direction="horizontal" gap={2}>
                  Are you invited?{" "}
                  <Link href="/register" className="text-secondary fw-bold">
                    Register now
                  </Link>
                </Stack>
              </Stack>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default Login;
