import React, { useRef } from "react";
import { Alert, Button, Form, Modal, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import { args } from "../../imports/utils";
import { SubmitButton } from "../../imports/components";
import {
  useConfirmDelete,
  useEndpoints,
  usePrivileges,
  useRoles,
} from "../../imports/hooks";

const AddEditPrivilege = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { roles } = useRoles();
  const { endpoints } = useEndpoints();
  const { dispatch } = useConfirmDelete();
  const { show, privilege, hide } = { ...args.dialog, ...props };

  const { create, update, state, validated, processing } = usePrivileges({
    onSuccess,
    id: privilege?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (!privilege?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        path: "privileges",
        onHide: () => hide(),
        key: ["privileges", "authorised"],
        value: {
          id: privilege?.id,
          name: `${privilege?.role?.title} ${privilege?.endpoint?.title}`,
        },
      },
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {privilege?.id ? "Edit" : "Add"} Privilege
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={privilege?.id ? update : create}
        >
          <Stack gap={3} className="mb-3">
            <Form.FloatingLabel label="Privilege role">
              <Form.Control
                name="role_id"
                type="text"
                as="select"
                className="text-bg-light"
                required
                defaultValue={privilege?.role?.id || ""}
              >
                <option disabled value="">
                  --- select ---
                </option>
                {roles.map((role) => {
                  return (
                    <option key={role.id} value={role.id}>
                      {role.title}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a role for this privilege
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            <Form.FloatingLabel label="Privilege endpoint">
              <Form.Control
                name="endpoint_id"
                type="text"
                as="select"
                className="text-bg-light"
                required
                defaultValue={privilege?.endpoint?.id || ""}
              >
                <option disabled value="">
                  --- select ---
                </option>
                {endpoints.map((endpoint) => {
                  return (
                    <option key={endpoint.id} value={endpoint.id}>
                      {endpoint.title}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a role for this privilege
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            {["create", "read", "update", "delete"].map((action) => {
              return (
                <Form.FloatingLabel key={action} label={`Allow ${action}`}>
                  <Form.Control
                    name={`can_${action}`}
                    type="text"
                    as="select"
                    className="text-bg-light"
                    defaultValue={
                      privilege?.id ? privilege?.[`can_${action}`] : false
                    }
                  >
                    <option value={false}>No, can not {action}</option>
                    <option value={true}>Yes, can {action}</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide an privilege title
                  </Form.Control.Feedback>
                </Form.FloatingLabel>
              );
            })}

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {privilege?.id ? "Update" : "Create"}
              </SubmitButton>

              {privilege?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditPrivilege;
