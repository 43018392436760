import { useContext } from "react";

import { AuthContext } from "../imports/context";

export default function Auth() {
  const context = useContext(AuthContext);

  if (!context) throw Error("AuthContext cannot be used outside of it's scope");

  return context;
}
