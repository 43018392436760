import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useToggle } from "../imports/hooks";
import { API, processData } from "../imports/utils";

const args = {
  id: "",
  clientKey: "",
  replace: false,
  successCode: 200,
  isMultipart: false,
  errorInterval: 4000,
  toggleOnSuccess: true,
  onSuccess: () => true,
};

export default function Update(id, path, props = { ...args }) {
  props = { ...args, ...props };

  const navigate = useNavigate();
  const client = useQueryClient();
  const { state, toggle } = useToggle();
  const [data, setData] = useState(null);
  const [progress, setProgress] = useState("0%");
  const [isValidated, setIsValidated] = useState(false);

  const { mutate, isLoading: updating } = useMutation(
    props.isMultipart ? API.UpdateForm : API.Update,
    {
      onSuccess: async (res) => {
        if (res) {
          if (res?.success && props.clientKey?.length > 0) {
            await client.invalidateQueries(props.clientKey);
          }

          if (!props.onSuccess(res)) {
            return;
          }

          if (res.success && res.status === props.successCode) {
            if (!props.toggleOnSuccess) {
              navigate(res.redirect, {
                state: { ...res },
                replace: props.replace,
              });
              return;
            }
          }

          setData(res);
          toggle(res, true, props.errorInterval);
        } else {
          toggle(
            { success: false, message: "An error was encountered." },
            true,
            props.errorInterval
          );
        }
      },
    }
  );

  function update(e, data = null, arrays = [], _id = null) {
    if (e && !data) {
      e.preventDefault();
      setIsValidated(false);

      if (e.currentTarget.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setIsValidated(true);
        return;
      }

      data = processData(e, arrays);
    }

    mutate({
      path,
      data,
      id: _id ?? id ?? props.id,
      onProgress: (res) => {
        const percent = Math.floor(res.progress * 100);

        setProgress(`${percent}%`);
      },
    });
  }

  return {
    update,
    updating,
    progress,
    updated: state,
    updatedData: data,
    canUpdate: isValidated,
  };
}
