import React from "react";
import { Stack } from "react-bootstrap";
import { BiMessageAltError } from "react-icons/bi";

function PanelNotFound() {
  return (
    <Stack
      gap={2}
      className="h-100 w-100 justify-content-center align-items-center"
    >
      <BiMessageAltError className="display-1 text-danger" />
      <p className="lead text-muted">Page not available</p>
    </Stack>
  );
}

export default PanelNotFound;
