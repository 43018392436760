import React, { useLayoutEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import {
  BroadcastMessage,
  DataTable,
  ReviewAccount,
  Toolbar,
} from "../imports/components";
import { useAccounts } from "../imports/hooks";

function Accounts() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [broadcast, setBroadcast] = useState(false);

  const { accounts: data, errorMessage: message } = useAccounts();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setAccounts(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        dataKey="accounts"
        title="Accounts"
        canCreate={false}
        addText="Invite New"
        onSearch={setAccounts}
        filters={["first_name", "last_name", "phone", "email"]}
        onAddClick={handleAddViewEdit}
      >
        <Button onClick={() => setBroadcast(true)} variant="warning" size="sm">
          Broadcast
        </Button>
      </Toolbar>

      <DataTable
        dataRef={ref}
        message={message}
        records={accounts}
        actionText="Review"
        columns={[
          ["Last Name", "last_name"],
          ["First Name", "first_name"],
          "Phone",
          "Email",
          ["City", ["geo_location", "city"]],
          ["Country", ["geo_location", "country"]],
          ["Region", ["geo_location", "regionName"]],
          ["Continent", ["geo_location", "continentCode"]],
        ]}
        onRecordClick={handleAddViewEdit}
      />

      <ReviewAccount
        show={show}
        account={selected}
        hide={() => handleAddViewEdit({}, false)}
      />

      <BroadcastMessage
        data={accounts}
        show={broadcast}
        hide={() => setBroadcast(false)}
        filterKey="first_name"
        filters={[
          { title: "Subscribers", value: "subs" },
          { title: "None-subscribers", value: "non-subs" },
        ]}
      />
    </>
  );
}

export default Accounts;
