import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useToggle } from "../imports/hooks";
import { API } from "../imports/utils";

const args = {
  clientKey: "",
  replace: false,
  successCode: 200,
  errorInterval: 4000,
  toggleOnSuccess: true,
  onSuccess: () => true,
};

export default function Delete(id, path, props = { ...args }) {
  props = { ...args, ...props };

  const navigate = useNavigate();
  const client = useQueryClient();
  const { state, toggle } = useToggle();
  const [data, setData] = useState(null);

  const { mutate, isLoading: removing } = useMutation(API.Delete, {
    onSuccess: (res) => {
      if (res) {
        if (res?.success && props.clientKey?.length > 0) {
          client.invalidateQueries(props.clientKey);
        }

        if (!props.onSuccess(res)) {
          return;
        }

        if (res.success && res.status === props.successCode) {
          if (!props.toggleOnSuccess) {
            navigate(res.redirect, {
              state: { ...res },
              replace: props.replace,
            });
            return;
          }
        }

        setData(res);
        toggle(res, true, props.errorInterval);
      } else {
        toggle(
          { success: false, message: "An error was encountered." },
          true,
          props.errorInterval
        );
      }
    },
  });

  function remove() {
    mutate({
      id,
      path,
    });
  }

  return {
    remove,
    removing,
    removed: state,
    removedData: data,
  };
}
