import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditGenre, DataTable, Toolbar } from "../imports/components";
import { useGenres } from "../imports/hooks";

function Genres() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [genres, setGenres] = useState([]);

  const { genres: data, errorMessage: message } = useGenres();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setGenres(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Genres"
        dataKey="genres"
        onSearch={setGenres}
        onAddClick={handleAddViewEdit}
        filters={["title", "slug", "description"]}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={genres}
        onRecordClick={handleAddViewEdit}
        columns={["Title", "Slug", "Description"]}
      />

      <AddEditGenre
        show={show}
        genre={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Genres;
