import { useMemo } from "react";
import { API, args } from "../imports/utils";
import { usePost, useUpdate } from "../imports/hooks";

export default function useMovie(
  props = { ...args.manage, param: null, value: null }
) {
  props = { ...args.manage, ...props, clientKey: "movies", path: "movies" };

  const { data, isLoading } = API.Get({
    key: props.clientKey,
    path: `${props.path}/all`,
  });

  const { related, isLoading: fetching } = API.Get({
    key: props.clientKey,
    path: `${props.path}/related-movie`,
    enabled: props.param !== null && props.value !== null,
  });

  const movies = useMemo(() => {
    if (isLoading) return [];

    return data?.message ? [] : data;
  }, [data, isLoading]);

  const { post, state, processing, isValidated } = usePost(props.path, {
    successCode: 201,
    isMultipart: true,
    toggleOnSuccess: true,
    clientKey: props.clientKey,
    onSuccess: (res) => props.onSuccess(res),
  });

  const { update, canUpdate, updated, updating } = useUpdate(
    props.id,
    props.path,
    {
      successCode: 200,
      isMultipart: true,
      toggleOnSuccess: true,
      clientKey: props.clientKey,
      onSuccess: (res) => props.onSuccess(res),
    }
  );

  return {
    movies,
    related,
    create: post,
    update: update,
    state: state || updated,
    errorMessage: data?.message,
    validated: isValidated || canUpdate,
    processing: isLoading || processing || updating || fetching,
  };
}
