import React, { useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import { useConfirmDelete, usePlans } from "../../imports/hooks";
import { PlanMeta } from "../../imports/components";
import SubmitButton from "../custom/SubmitButton";
import { Continents } from "../../imports/data";
import { args } from "../../imports/utils";

const AddEditPlan = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { dispatch } = useConfirmDelete();
  const { show, plan, hide } = { ...args.dialog, ...props };

  const { create, update, state, processing, validated } = usePlans({
    onSuccess,
    id: plan?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (!plan?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        path: "plans",
        onHide: () => hide(),
        key: "plans",
        value: { id: plan?.id, name: plan?.title },
      },
    });
  }

  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {plan?.id ? "Edit" : "Add"} Plan
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={plan?.id ? update : create}
        >
          <Stack gap={3}>
            <Row className="gy-3">
              <Col>
                <section className="mb-3">
                  <small className="fw-bold">Description</small>
                  <Form.FloatingLabel label="Plan title">
                    <Form.Control
                      name="title"
                      type="text"
                      placeholder="Plan title"
                      className="text-bg-light"
                      defaultValue={plan?.title}
                      required
                      autoFocus
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide plan title
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>
                </section>

                <section>
                  <small className="fw-bold">Meta</small>

                  <PlanMeta plan={plan} />
                </section>
              </Col>

              <Col md={6}>
                <small className="fw-bold">Pricing</small>

                <Stack gap={2}>
                  {Continents.map((continent, index) => {
                    return (
                      <Row key={continent.abbr}>
                        <small>{continent.name}</small>
                        <Form.Control
                          name={`prices[${index}][region]`}
                          value={continent.abbr}
                          type="hidden"
                          required
                        />
                        <Col>
                          <Form.Control
                            type="number"
                            min={0}
                            step={0.01}
                            placeholder="Enter amount"
                            name={`prices[${index}][amount]`}
                            className="text-bg-light"
                            defaultValue={
                              plan?.id
                                ? plan?.prices?.find(
                                    (price) => price.region === continent.abbr
                                  ).amount
                                : ""
                            }
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter an amount
                          </Form.Control.Feedback>
                        </Col>

                        <Col>
                          <Form.Control
                            as="select"
                            name={`prices[${index}][period]`}
                            className="text-bg-light"
                            defaultValue={
                              plan?.id
                                ? plan?.prices?.find(
                                    (price) => price.region === continent.abbr
                                  ).period
                                : ""
                            }
                            required
                          >
                            <option disabled value="">
                              Select period
                            </option>
                            {["Month", "Year"].map((period) => {
                              return (
                                <option key={period} value={period}>
                                  {period}
                                </option>
                              );
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Select a period
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    );
                  })}
                </Stack>
              </Col>
            </Row>

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}

            <Stack
              direction="horizontal"
              gap={3}
              className="justify-content-between"
            >
              <Stack direction="horizontal" gap={3}>
                <SubmitButton
                  isProcessing={processing}
                  size="md"
                  className="rounded"
                >
                  {plan?.id ? "Update" : "Create"}
                </SubmitButton>

                {plan?.id && (
                  <Button
                    onClick={handleDelete}
                    variant="outline-danger"
                    type="button"
                    className="letter-spaced fw-bolder"
                  >
                    Delete
                  </Button>
                )}
              </Stack>

              <Button
                onClick={hide}
                variant="light"
                type="button"
                className="letter-spaced fw-bolder border"
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditPlan;
