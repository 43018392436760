import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditRole, DataTable, Toolbar } from "../imports/components";
import { useRoles } from "../imports/hooks";

function Roles() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [roles, setRoles] = useState([]);
  const { roles: data, errorMessage: message } = useRoles();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setRoles(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        dataKey="roles"
        title="Admin Roles"
        onSearch={setRoles}
        filters={["title", "slug"]}
        onAddClick={handleAddViewEdit}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={roles}
        columns={["Title", "Slug", ["Deletable", "is_deletable"]]}
        onRecordClick={handleAddViewEdit}
      />

      <AddEditRole
        show={show}
        role={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Roles;
