import React, { useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { usePost } from "../../imports/hooks";
import SubmitButton from "../custom/SubmitButton";

const ReviewSubscriber = ({ subscriber, show, hide }) => {
  const ref = useRef();
  const { post, state, processing, isValidated } = usePost(
    "static/send-message",
    {
      successCode: 201,
      toggleOnSuccess: true,
    }
  );

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">Review Subscriber</Modal.Title>
      </Modal.Header>

      <Modal.Body as={Stack} gap={3} className="mb-2">
        <Stack gap={2} className="mb-2">
          <small className="fw-bold">Account Info</small>
          <Stack className="border-bottom">
            <small className="text-muted">Full Name</small>
            <p className="mb-0">{subscriber?.full_name}</p>
          </Stack>

          <Stack className="border-bottom">
            <small className="text-muted">Phone</small>
            <p className="mb-0">{subscriber?.phone}</p>
          </Stack>

          <Row>
            <Col>
              <small className="text-muted">City</small>
              <p className="mb-0">{subscriber?.geo_location?.city}</p>
            </Col>
            <Col>
              <small className="text-muted">Country</small>
              <p className="mb-0">{subscriber?.geo_location?.country}</p>
            </Col>
            <Col>
              <small className="text-muted">Region</small>
              <p className="mb-0">{subscriber?.geo_location?.regionName}</p>
            </Col>
            <Col>
              <small className="text-muted">Continent</small>
              <p className="mb-0">{subscriber?.geo_location?.continentCode}</p>
            </Col>
          </Row>
        </Stack>

        <Stack gap={2} className="mb-2">
          <small className="fw-bold">Plan Info</small>
          <Row>
            <Col>
              <small className="text-muted">Title</small>
              <p className="mb-0">{subscriber?.plan?.title}</p>
            </Col>
            <Col>
              <small className="text-muted">Amount</small>
              <p className="mb-0">{subscriber?.plan?.amount}</p>
            </Col>
            <Col>
              <small className="text-muted">Period</small>
              <p className="mb-0">{subscriber?.plan?.period}</p>
            </Col>
            <Col>
              <small className="text-muted">Expires</small>
              <p className="mb-0">
                {new Date(subscriber?.expires_at).toLocaleDateString()}
              </p>
            </Col>
          </Row>
        </Stack>

        <Form
          ref={ref}
          noValidate
          onSubmit={post}
          validated={isValidated}
          className="mb-3"
        >
          <Stack gap={2} className="mb-2">
            <small className="fw-bold">Notify Subscriber</small>
            <Form.Control
              type="hidden"
              defaultValue={subscriber?.phone}
              name="phone"
            />
            <Form.FloatingLabel label="Type your message">
              <Form.Control
                name="message"
                type="text"
                as="textarea"
                placeholder="Type your message"
                className="text-bg-light"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the message to send
              </Form.Control.Feedback>
            </Form.FloatingLabel>
          </Stack>

          {state && (
            <Alert variant={state?.success ? "success" : "danger"}>
              {state?.message}
            </Alert>
          )}

          <Stack direction="horizontal">
            <SubmitButton isProcessing={processing} size="sm" variant="info">
              Send
            </SubmitButton>
          </Stack>
        </Form>

        <Button onClick={hide} size="lg" variant="secondary">
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewSubscriber;
