import React, { useRef, useState } from "react";
import { Alert, Button, Form, Modal, NavLink, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  useConfirmDelete,
  useContents,
  useFilePreview,
} from "../../imports/hooks";
import { resPath } from "../../imports/utils";
import SubmitButton from "../custom/SubmitButton";
import { useEffect } from "react";

const args = {
  content: {},
  show: false,
  hide: () => {},
};

const AddEditContent = (props = { ...args }) => {
  const ref = useRef();
  const { dispatch } = useConfirmDelete();
  const { dispatch: preview } = useFilePreview();
  const { content, hide, show } = { ...args, ...props };
  const [path, setPath] = useState(props.content?.path || null);

  const { create, update, validated, state, processing } = useContents({
    onSuccess,
    id: content?.id,
  });

  function onSuccess(res) {
    if (res?.success && !content?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        onHide: hide,
        key: "contents",
        path: "contents",
        value: { id: content?.id, name: content?.title },
      },
    });
  }

  function updatePath(value, key) {
    if (value && value?.length > 0) {
      setPath((pre) => {
        const arr = pre?.split ? pre.split("/") : [];

        switch (key) {
          case "title":
            return `${arr?.[0]}/${arr?.[1]}/${value}/${value}.m3u8`;
          case "category":
            return `${arr?.[0]}/${value}/${arr?.[2]}/${arr?.[3]}`;
          case "media_type":
            return `${value}s/${arr?.[1]}/${arr?.[2]}/${arr?.[3]}`;
          default:
            return pre;
        }
      });
    }
  }

  useEffect(() => {
    if (show && content?.path) {
      setPath(content?.path);
    }

    return () => setPath("");
  }, [show, content?.path]);

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {content?.id ? "Edit" : "Add"} Content
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={content?.id ? update : create}
        >
          <Stack gap={2} className="mb-3">
            <Form.FloatingLabel label="Content media type">
              <Form.Control
                autoFocus={true}
                name="media_type"
                type="text"
                as="select"
                placeholder="Content media type"
                className="text-bg-light"
                defaultValue={content?.media_type || ""}
                required
                onChange={({ target }) =>
                  updatePath(target.value, "media_type")
                }
              >
                <option disabled value="">
                  --- select ---
                </option>
                {["Video"].map((category, index) => {
                  return (
                    <option
                      key={`category_${index}`}
                      value={category?.toLowerCase()}
                    >
                      {category}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select content media type
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            <Form.FloatingLabel label="Content category">
              <Form.Control
                name="category"
                type="text"
                as="select"
                placeholder="Content category"
                className="text-bg-light"
                defaultValue={content?.category || ""}
                required
                onChange={({ target }) => updatePath(target.value, "category")}
              >
                <option disabled value="">
                  --- select ---
                </option>
                {["Movie", "Trailer", "Poster"].map((category, index) => {
                  return (
                    <option
                      key={`category_${index}`}
                      value={category?.toLowerCase()}
                    >
                      {category}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a content category
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            <Form.FloatingLabel label="Content title">
              <Form.Control
                name="title"
                type="text"
                placeholder="Content title"
                className="text-bg-light"
                defaultValue={content?.title}
                required
                onChange={({ target }) => updatePath(target.value, "title")}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a content name
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            <Form.FloatingLabel label="Content path">
              <Form.Control
                type="text"
                placeholder="Content title"
                className="text-bg-light"
                required
                defaultValue={content?.id ? content.path : path}
                name={content?.id && path !== content?.path ? "" : "path"}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a content name
              </Form.Control.Feedback>
              <NavLink
                title={`${resPath}/${content?.id ? content.path : path}`}
                onClick={() => {
                  preview({
                    type: "SHOW",
                    payload: {
                      fileType: content?.media_type ?? "video",
                      path: `${resPath}/${content?.id ? content.path : path}`,
                    },
                  });
                }}
                className="hover-underline"
              >
                <small>Path (click to preview)</small>
              </NavLink>
            </Form.FloatingLabel>

            {content?.id && path !== content?.path && (
              <Form.FloatingLabel label="New content path">
                <Form.Control
                  name="path"
                  type="text"
                  placeholder="Content title"
                  className="text-bg-light"
                  defaultValue={path}
                />
                <NavLink
                  title={`${resPath}/${path}`}
                  onClick={() => {
                    preview({
                      type: "SHOW",
                      payload: {
                        path: `${resPath}/${path}`,
                        fileType: content?.media_type ?? "video",
                      },
                    });
                  }}
                  className="hover-underline"
                >
                  <small>Path (click to preview)</small>
                </NavLink>
              </Form.FloatingLabel>
            )}

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {content?.id ? "Update" : "Create"}
              </SubmitButton>

              {content?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditContent;
