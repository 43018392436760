import React from "react";
import { Button, Stack } from "react-bootstrap";

const SubmitButton = ({
  children,
  size,
  id = "",
  className,
  disabled = false,
  variant = "primary",
  isProcessing = false,
}) => {
  return (
    <Button
      id={id}
      size={size}
      type="submit"
      variant={variant}
      className={className}
      disabled={disabled || isProcessing}
    >
      <Stack
        direction="horizontal"
        className="justify-content-center fw-bold letter-spaced"
      >
        {isProcessing ? "Please wait..." : children}
      </Stack>
    </Button>
  );
};

export default SubmitButton;
