import React from "react";
import { Route, Routes } from "react-router-dom";
import Layouts from "./imports/layouts";
import Pages from "./imports/pages";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layouts.Authenticate />}>
        <Route index element={<Pages.Login />} />
        <Route path="login" element={<Pages.Login />} />
        <Route path="register" element={<Pages.Register />} />
        <Route path="reset-password" element={<Pages.PasswordReset />} />
        <Route path="forgot-password" element={<Pages.ForgotPassword />} />
      </Route>

      <Route path="/admin" element={<Layouts.AdminPanel />}>
        <Route index element={<Pages.Dashboard />} />
        <Route path="plans" element={<Pages.Plans />} />
        <Route path="roles" element={<Pages.Roles />} />
        <Route path="movies" element={<Pages.Movies />} />
        <Route path="genres" element={<Pages.Genres />} />
        <Route path="admins" element={<Pages.Admins />} />
        <Route path="*" element={<Pages.PanelNotFound />} />
        <Route path="devices" element={<Pages.Devices />} />
        <Route path="banner" element={<Pages.Endpoints />} />
        <Route path="accounts" element={<Pages.Accounts />} />
        <Route path="methods" element={<Pages.Methods />} />
        <Route path="contents" element={<Pages.Contents />} />
        <Route path="endpoints" element={<Pages.Endpoints />} />
        <Route path="dashboard" element={<Pages.Dashboard />} />
        <Route path="privileges" element={<Pages.Privileges />} />
        <Route path="categories" element={<Pages.Categories />} />
        <Route path="tags" element={<Pages.Tags />} />
        <Route path="subscribers" element={<Pages.Subscribers />} />
        <Route path="banners" element={<Pages.Banner />} />
      </Route>
    </Routes>
  );
}
