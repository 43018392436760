import React from "react";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Link = ({ ...props }) => {
  const navigate = useNavigate();

  function handleOnClick(e) {
    e.preventDefault();

    navigate(props?.href);
  }

  return (
    <NavLink onClick={handleOnClick} {...props}>
      {props?.children}
    </NavLink>
  );
};

export default Link;
