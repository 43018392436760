import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditMethod, DataTable, Toolbar } from "../imports/components";
import { useMethods } from "../imports/hooks";

function Methods() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [methods, setMethods] = useState([]);

  const { methods: data, errorMessage: message } = useMethods();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setMethods(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Payment Methods"
        dataKey="methods"
        onSearch={setMethods}
        onAddClick={handleAddViewEdit}
        filters={["name"]}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={methods}
        onRecordClick={handleAddViewEdit}
        columns={["Title", "Slug", ["Path", "url"]]}
      />

      <AddEditMethod
        show={show}
        method={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Methods;
