import { useEffect, useState } from "react";
import useConfirmDelete from "./confirm";

export default function Toggle() {
  const { show } = useConfirmDelete();
  const [state, setState] = useState(null);

  const toggle = (data, timeout = true, interval = 4000) => {
    setState(data);

    if (timeout) {
      setTimeout(() => {
        setState(null);
      }, interval);
    }
  };

  useEffect(() => {
    return () => {
      setState(null);
    };
  }, [show]);

  return {
    state,
    toggle,
  };
}
