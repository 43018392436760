import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditContent, DataTable, Toolbar } from "../imports/components";
import { useContents } from "../imports/hooks";

function Contents() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [contents, setContents] = useState([]);
  const { contents: data, errorMessage: message } = useContents();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setContents(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Contents"
        dataKey="contents"
        onSearch={setContents}
        onAddClick={handleAddViewEdit}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={contents}
        onRecordClick={handleAddViewEdit}
        columns={["Title", "Category", ["Media Type", "media_type"]]}
      />

      <AddEditContent
        show={show}
        content={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Contents;
