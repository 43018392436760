import { createContext, useReducer } from "react";

export const ConfirmDeleteContext = createContext();

export default function ConfirmDeleteContextProvider({ children }) {
  const [state, dispatch] = useReducer(ConfirmDeleteContextReducer, {
    key: "",
    path: "",
    param: "id",
    show: false,
    onHide: () => {},
    value: { id: 0, name: "" },
  });

  return (
    <ConfirmDeleteContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ConfirmDeleteContext.Provider>
  );
}

function ConfirmDeleteContextReducer(state, action) {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        ...action.payload,
        show: true,
      };
    case "HIDE":
      return {
        ...state,
        ...action.payload,
        show: false,
      };

    default:
      return state;
  }
}
