import React, { useRef } from "react";
import { Alert, Button, Form, Modal, Stack } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useConfirmDelete, useEndpoints } from "../../imports/hooks";
import SubmitButton from "../custom/SubmitButton";
import { args } from "../../imports/utils";

const AddEditEndpoint = (props = { ...args.dialog }) => {
  const ref = useRef();
  const client = useQueryClient();
  const { dispatch } = useConfirmDelete();
  const { endpoint, hide, show } = { ...args.dialog, ...props };

  const { create, update, state, processing, validated } = useEndpoints({
    onSuccess,
    id: endpoint?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (res?.success) {
      client.invalidateQueries("authorised");

      if (!endpoint?.id) {
        toast.success(res?.message, { hideProgressBar: true });
        hide();
        return false;
      }
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        onHide: hide,
        path: "endpoints",
        key: ["endpoints", "authorised"],
        value: { id: endpoint?.id, name: endpoint?.title },
      },
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {endpoint?.id ? "Edit" : "Add"} Endpoint
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={endpoint?.id ? update : create}
        >
          <Stack gap={3} className="mb-3">
            <Form.FloatingLabel label="Endpoint title">
              <Form.Control
                name="title"
                type="text"
                placeholder="Endpoint title"
                className="text-bg-light"
                defaultValue={endpoint?.title}
                required
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                Please provide an endpoint title
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {endpoint?.id ? "Update" : "Create"}
              </SubmitButton>

              {endpoint?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditEndpoint;
