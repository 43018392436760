import { useMemo } from "react";
import { API, args } from "../imports/utils";
import { usePost } from "../imports/hooks";

export default function useSubscribers(props = { ...args.manage }) {
  props = {
    ...args.manage,
    ...props,
    clientKey: "subscribers",
    path: "static/subscribers",
  };

  const { data, isLoading } = API.Get({
    key: props.clientKey,
    enabled: props.enabled,
    path: props.path,
  });

  const subscribers = useMemo(() => {
    if (isLoading) return [];

    return data?.message ? [] : data;
  }, [data, isLoading]);

  const to_renew = useMemo(() => {
    if (subscribers?.filter) {
      return subscribers.filter((subscriber) => subscriber.expired);
    }

    return [];
  }, [subscribers]);

  const { post, state, processing, isValidated } = usePost(
    `${props.path}/renew`,
    {
      successCode: 201,
      toggleOnSuccess: true,
      clientKey: props.clientKey,
      onSuccess: (res) => props.onSuccess(res),
    }
  );

  function renew() {
    if (to_renew.length > 0) {
      const data = new FormData();
      data.append(
        "to_renew[]",
        to_renew.map((tr) => tr.id)
      );

      post(null, data);
    }
  }

  return {
    renew,
    subscribers,
    state: state,
    validated: isValidated,
    to_renew: to_renew.length,
    errorMessage: data?.message,
    processing: isLoading || processing,
  };
}
