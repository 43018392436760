import Login from "../pages/Login";
import Register from "../pages/Register";
import PasswordReset from "../pages/PasswordReset";
import ForgotPassword from "../pages/ForgotPassword";

import Roles from "../pages/Roles";
import Plans from "../pages/Plans";
import Genres from "../pages/Genres";
import Movies from "../pages/Movies";
import Admins from "../pages/Admins";
import Devices from "../pages/Devices";
import Methods from "../pages/Methods";
import Accounts from "../pages/Accounts";
import Contents from "../pages/Contents";
import Endpoints from "../pages/Endpoints";
import Dashboard from "../pages/Dashboard";
import Categories from "../pages/Categories";
import Privileges from "../pages/Privileges";
import Tags from "../pages/Tags";
import Subscribers from "../pages/Subscribers";
import PanelNotFound from "../pages/PanelNotFound";
import Banner from "../pages/Banner";

const Pages = {
  Login,
  Roles,
  Plans,
  Genres,
  Admins,
  Movies,
  Devices,
  Accounts,
  Register,
  Contents,
  Dashboard,
  Endpoints,
  Methods,
  Privileges,
  Categories,
  Tags,
  Subscribers,
  Banner,
  PasswordReset,
  ForgotPassword,
  PanelNotFound,
};

export default Pages;
