import React from "react";
import { Alert, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";
import { Link, SubmitButton } from "../imports/components";
import { usePost } from "../imports/hooks";
import { BiCheckCircle } from "react-icons/bi";

function PasswordReset() {
  const { state: response } = useLocation();

  const { state, post, data, isValidated, processing } = usePost(
    "admins/reset-password",
    {
      toggleOnSuccess: true,
    }
  );

  return !response && !response?.success ? (
    <Navigate to={-1} replace={true} />
  ) : (
    <div className="login-bg">
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col xxl={4} lg={5} md={7} sm={9}>
            <Card className="login-card shadow border-0">
              <Card.Body className="px-4 py-5">
                {data && data?.success ? (
                  <Stack className="text-center">
                    <p className="mb-3">
                      <BiCheckCircle className="text-primary display-1" />
                    </p>
                    <p className="text-secondary fs-5 fw-bold mb-0">
                      Password reset successfully
                    </p>
                    <p>
                      <small>You can now login with your new password</small>
                    </p>

                    <Stack
                      direction="horizontal"
                      className="justify-content-center"
                    >
                      <Link
                        href="/"
                        className="px-4 py-2 text-bg-primary rounded"
                      >
                        Return to login
                      </Link>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <h2 className="fw-bolder text-primary">Reset Password</h2>
                    <p className="m-0">
                      Create a new password for your account
                    </p>

                    <Form
                      noValidate
                      validated={isValidated}
                      className="w-100 my-4"
                      onSubmit={post}
                    >
                      <Stack gap={3} className="mb-4">
                        <Stack>
                          <Stack
                            direction="horizontal"
                            className="justify-content-between align-items-center"
                          >
                            <p className="fw-bold mb-0">Verify OTP</p>
                            <Link href="/forgot-password">
                              <small className="text-info fw-bold">
                                Resend code
                              </small>
                            </Link>
                          </Stack>
                          <Form.FloatingLabel label="OTP Code">
                            <Form.Control
                              name="otp_code"
                              type="number"
                              placeholder="OTP Code"
                              className="text-bg-light"
                              maxLength={6}
                              required
                              autoFocus
                            />

                            <Form.Control.Feedback type="invalid">
                              OTP code is required
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>
                          <small>
                            Enter the code sent to{" "}
                            {response?.message?.match(/\d+/)}
                          </small>
                        </Stack>

                        <Stack>
                          <p className="fw-bold mb-0">Create Password</p>
                          <Form.FloatingLabel
                            label="New password"
                            className="mb-2"
                          >
                            <Form.Control
                              name="secret"
                              type="password"
                              placeholder="New password"
                              className="text-bg-light"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a new password
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>

                          <Form.FloatingLabel label="Confirm new password">
                            <Form.Control
                              name="confirm"
                              type="password"
                              placeholder="Confirm new password"
                              className="text-bg-light"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a new password
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>
                        </Stack>

                        {state && (
                          <Alert variant="danger">{state.message}</Alert>
                        )}
                      </Stack>

                      <Stack gap={2}>
                        <SubmitButton isProcessing={processing} size="lg">
                          SUBMIT
                        </SubmitButton>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="justify-content-center"
                        >
                          Remembered password?
                          <Link href="/" className="text-info">
                            Login
                          </Link>
                        </Stack>
                      </Stack>
                    </Form>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PasswordReset;
