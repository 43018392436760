import React, { useLayoutEffect, useRef, useState } from "react";
import { Alert, Button, Form, Modal, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import { useCategories, useConfirmDelete } from "../../imports/hooks";
import SubmitButton from "../custom/SubmitButton";
import { args } from "../../imports/utils";

const AddEditCategory = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { dispatch } = useConfirmDelete();
  const [dataType, setDataType] = useState("string");
  const { category, hide, show } = { ...args.dialog, ...props };

  const { create, update, processing, validated, state } = useCategories({
    onSuccess,
    id: category?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (res?.success && !category?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleFormSubmit(e) {
    if (!category?.id) {
      create(e, null, ["props"]);
    } else {
      update(e, null, ["props"]);
    }
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        onHide: hide,
        path: "categories",
        key: "categories",
        value: { id: category?.id, name: category?.title },
      },
    });
  }

  useLayoutEffect(
    () => setDataType(category?.data_type ? category?.data_type : ""),
    [category]
  );

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {category?.id ? "Edit" : "Add"} Category
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={handleFormSubmit}
        >
          <Stack gap={3} className="mb-3">
            <Form.FloatingLabel label="Category title">
              <Form.Control
                name="title"
                type="text"
                placeholder="Category title"
                className="text-bg-light"
                defaultValue={category?.title}
                required
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                Please provide category title
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            <Form.FloatingLabel label="Data type">
              <Form.Control
                name="data_type"
                type="text"
                onChange={({ target }) =>
                  setDataType(target.value?.toLocaleLowerCase())
                }
                placeholder="Data type"
                className="text-bg-light"
                defaultValue={category?.data_type}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide category data type
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            {dataType === "object" && (
              <Form.FloatingLabel label="Props">
                <Form.Control
                  name="props"
                  type="text"
                  placeholder="Props"
                  className="text-bg-light"
                  defaultValue={category?.props?.join(", ")}
                  required
                />
                <small>Separate each prop with a comma</small>
                <Form.Control.Feedback type="invalid">
                  Please provide category props
                </Form.Control.Feedback>
              </Form.FloatingLabel>
            )}

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {category?.id ? "Update" : "Create"}
              </SubmitButton>

              {category?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditCategory;
