import React, { useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import SubmitButton from "../custom/SubmitButton";
import { args } from "../../imports/utils";
import { useManager } from "../../imports/hooks";

const ReviewAccount = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { account, show, hide } = props;

  const manager = useManager({
    clientKey: "accounts",
    path: "static/accounts",
    postPath: "static/send-message",
  });

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">Review Account</Modal.Title>
      </Modal.Header>

      <Modal.Body as={Stack} gap={3} className="mb-2">
        <Stack>
          <small className="fw-bold">Personal Info</small>
          {(account?.first_name || account?.last_name) && (
            <Stack className="border-bottom mb-2">
              <small className="text-muted">Full Name</small>
              <p className="mb-0">
                {account?.last_name} {account?.first_name}
              </p>
            </Stack>
          )}

          <Stack className="border-bottom mb-2">
            <small className="text-muted">Phone</small>
            <p className="mb-0">{account?.phone}</p>
          </Stack>

          {account?.email && (
            <Stack className="border-bottom">
              <small className="text-muted">Email</small>
              <p className="mb-0">{account?.email}</p>
            </Stack>
          )}
        </Stack>

        <Stack className="border-bottom pb-2">
          <small className="fw-bold">Location Info</small>
          <Row>
            <Col>
              <small className="text-muted">City</small>
              <p className="mb-0">{account?.geo_location?.city}</p>
            </Col>
            <Col>
              <small className="text-muted">Country</small>
              <p className="mb-0">{account?.geo_location?.country}</p>
            </Col>
            <Col>
              <small className="text-muted">Region</small>
              <p className="mb-0">{account?.geo_location?.regionName}</p>
            </Col>
            <Col>
              <small className="text-muted">Continent</small>
              <p className="mb-0">{account?.geo_location?.continentCode}</p>
            </Col>
          </Row>
        </Stack>

        <Form
          ref={ref}
          noValidate
          validated={manager.canUpdate}
          onSubmit={(e) => manager.update(e, null, [], account.id)}
        >
          <Stack className="mb-2">
            <small className="fw-bold">Change Info</small>
            <Form.Control
              type="hidden"
              defaultValue={account?.phone}
              name="old_phone"
            />
            <Form.FloatingLabel label="Change phone">
              <Form.Control
                name="new_phone"
                type="text"
                placeholder="Change phone"
                className="text-bg-light"
                required
              />
              <p className="text-bg-warning py-0 px-1 m-0">
                <small>Don't forget to include the country code</small>
              </p>
              <Form.Control.Feedback type="invalid">
                Please enter the new phone number
              </Form.Control.Feedback>
            </Form.FloatingLabel>
          </Stack>

          {manager.updated && (
            <Alert variant={manager.updated?.success ? "success" : "danger"}>
              {manager.updated?.message}
            </Alert>
          )}

          <Stack direction="horizontal">
            <SubmitButton
              isProcessing={manager.updating}
              size="sm"
              className="rounded"
            >
              Update
            </SubmitButton>
          </Stack>
        </Form>

        <Form
          ref={ref}
          noValidate
          onSubmit={manager.create}
          validated={manager.canCreate}
          className="mb-3"
        >
          <Stack className="mb-2">
            <small className="fw-bold">Notify User</small>
            <Form.Control
              type="hidden"
              defaultValue={account?.phone}
              name="phone"
            />
            <Form.FloatingLabel label="Type your message">
              <Form.Control
                name="message"
                type="text"
                as="textarea"
                placeholder="Type your message"
                className="text-bg-light"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the message to send
              </Form.Control.Feedback>
            </Form.FloatingLabel>
          </Stack>

          {manager.created && (
            <Alert variant={manager.created?.success ? "success" : "danger"}>
              {manager.created?.message}
            </Alert>
          )}

          <Stack direction="horizontal">
            <SubmitButton
              isProcessing={manager.creating}
              size="sm"
              variant="info"
            >
              Send
            </SubmitButton>
          </Stack>
        </Form>

        <Button onClick={hide} size="lg" variant="secondary">
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewAccount;
