import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/js/bootstrap.bundle";

import "./styles/app.css";
import "./styles/app.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";

import {
  AuthContextProvider,
  ConfirmDeleteContextProvider,
} from "./imports/context";

const client = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <AuthContextProvider>
          <ConfirmDeleteContextProvider>
            <App />
          </ConfirmDeleteContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
