import React, { useLayoutEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  NavLink,
  Row,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { args } from "../../imports/utils";
import {
  useConfirmDelete,
  useContents,
  useGenres,
  useMovies,
  usePlans,
  useTags,
} from "../../imports/hooks";
import { ImageSelect } from "../../imports/components";
import SubmitButton from "../custom/SubmitButton";
import { MdDelete } from "react-icons/md";
import { useMemo } from "react";

const AddEditMovie = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { dispatch } = useConfirmDelete();
  const { movie, hide, show } = { ...args.dialog, ...props };

  const { create, update, state, processing, validated } = useMovies({
    onSuccess,
    id: movie?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (!movie?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }
    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        path: "movies",
        onHide: () => hide(),
        key: "movies",
        value: { id: movie?.id, name: movie?.title?.text },
      },
    });
  }

  const arrays = ["maturity[reasons]"];

  return (
    <Modal
      size="xl"
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {movie?.id ? "Edit" : "Add"} Movie
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={(e) =>
            movie?.id ? update(e, null, arrays) : create(e, null, arrays)
          }
        >
          <Stack gap={3} className="mb-3">
            <Row className="gy-3 gx-2">
              <Col>
                <small className="fw-bold">Description</small>
                <Stack gap={2}>
                  <Form.FloatingLabel label="Movie title">
                    <Form.Control
                      name="title[text]"
                      type="text"
                      placeholder="Movie title"
                      className="text-bg-light"
                      defaultValue={movie?.title?.text}
                      required
                      autoFocus
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a movie title
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>

                  <Form.FloatingLabel label="Movie release year">
                    <Form.Control
                      name="year_released"
                      type="text"
                      placeholder="Movie release year"
                      className="text-bg-light"
                      defaultValue={movie?.year_released}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide movie release year
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>

                  <Form.FloatingLabel label="Movie duration">
                    <Form.Control
                      name="duration"
                      type="number"
                      placeholder="Movie duration"
                      className="text-bg-light"
                      defaultValue={movie?.duration}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide movie duration
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>

                  <MovieType movie={movie} />

                  <Form.FloatingLabel label="Movie maturity age">
                    <Form.Control
                      name="maturity[age]"
                      type="number"
                      placeholder="Movie maturity age"
                      className="text-bg-light"
                      defaultValue={movie?.maturity?.age}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide movie maturity age
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>

                  <Form.FloatingLabel label="Movie maturity reasons">
                    <Form.Control
                      name="maturity[reasons]"
                      type="text"
                      placeholder="Movie maturity reason"
                      className="text-bg-light"
                      defaultValue={
                        movie?.maturity?.reasons?.join &&
                        movie.maturity.reasons.join(", ")
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide movie maturity reasons
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>

                  <MovieGenre movie={movie} />
                </Stack>
              </Col>

              <Col lg className="h-100">
                <Stack gap={3}>
                  <Stack>
                    <small className="fw-bold">Creator</small>
                    <Form.FloatingLabel label="Movie creator">
                      <Form.Control
                        name="creator"
                        type="text"
                        placeholder="Movie creator"
                        className="text-bg-light"
                        defaultValue={movie?.creator}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide movie maturity reasons
                      </Form.Control.Feedback>
                    </Form.FloatingLabel>
                  </Stack>

                  <Stack gap={2}>
                    <MovieCasts movie={movie} />
                  </Stack>

                  <Stack gap={2}>
                    <MovieTags movie={movie} />
                  </Stack>
                </Stack>
              </Col>

              <Col xl className="h-100">
                <Stack gap={3}>
                  <Stack>
                    <small className="fw-bold">Images</small>
                    <Row className="pt-2">
                      <Col className="text-center">
                        <small>Title Image</small>
                        <ImageSelect
                          id="text-image"
                          name="image"
                          multiple={false}
                          src={movie?.title?.image}
                        />
                      </Col>

                      <Col className="text-center">
                        <small>Poster Image</small>
                        <ImageSelect
                          name="poster"
                          multiple={false}
                          src={movie?.poster}
                        />
                      </Col>
                    </Row>
                  </Stack>

                  <Stack gap={3}>
                    <MovieContents movie={movie} show={show} />
                  </Stack>
                </Stack>
              </Col>
            </Row>

            <Form.Control
              as="textarea"
              name="extract"
              required
              defaultValue={movie?.extract}
              placeholder="Movie extract..."
              className="text-bg-light"
            />

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {movie?.id ? "Update" : "Create"}
              </SubmitButton>

              {movie?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

function MovieType({ movie }) {
  const [movieType, setMovieType] = useState("movie");

  return (
    <>
      <Form.FloatingLabel label="Movie type">
        <Form.Control
          as="select"
          onChange={({ target }) => setMovieType(target.value)}
          name="type"
          defaultValue={movie?.type || ""}
          className="text-bg-light"
          required
        >
          <option disabled value="">
            --- select ---
          </option>
          {["Episode", "Movie", "Series"].map((typ, key) => {
            return (
              <option key={`type_${key}`} value={typ.toLowerCase()}>
                {typ}
              </option>
            );
          })}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          Please select movie type
        </Form.Control.Feedback>
      </Form.FloatingLabel>

      {(movieType === "series" || movie?.type === "series") && (
        <Form.Group>
          <Form.FloatingLabel label="Movie seasons with comma">
            <Form.Control
              name="seasons"
              placeholder="Movie seasons with comma"
              defaultValue={movie?.seasons?.join && movie.seasons.join(", ")}
              className="text-bg-light"
              required
            />
          </Form.FloatingLabel>
          <Form.Control.Feedback type="invalid">
            Please provide movie seasons
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {(movieType === "episode" || movie?.type === "episode") && (
        <MovieRelation movie={movie} />
      )}
    </>
  );
}

function MovieRelation(movie) {
  const { related: series } = useMovies({
    param: "type",
    value: "series",
  });

  const [selected, setSelected] = useState({});

  const handleSeriesSelected = ({ target }) => {
    if (series && !series?.status) {
      const got = series.find((s) => s.id === target.value);

      setSelected(got);
    } else {
      setSelected({});
    }
  };

  return (
    <>
      <Form.FloatingLabel label="Movie parent movie">
        <Form.Control
          as="select"
          onChange={handleSeriesSelected}
          name="movie_id"
          defaultValue={movie?.movie_id || ""}
          className="text-bg-light"
          required
        >
          <option value="">--- select ---</option>
          {series?.map &&
            series.map((series) => {
              return (
                series.id !== movie?.id && (
                  <option key={series?.id} value={series.id}>
                    {series.title.text}
                  </option>
                )
              );
            })}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          Please provide a parent movie for this episode
        </Form.Control.Feedback>
      </Form.FloatingLabel>

      <Form.FloatingLabel label="Movie parent season">
        <Form.Control
          as="select"
          name="of_season"
          defaultValue={movie?.of_season || ""}
          className="text-bg-light"
          required
        >
          <option disabled value="">
            --- select ---
          </option>
          {selected?.seasons?.map &&
            selected.seasons.map((season, key) => {
              return (
                <option key={`season_${key}`} value={season}>
                  {season}
                </option>
              );
            })}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          Please provide a parent season for this episode
        </Form.Control.Feedback>
      </Form.FloatingLabel>
    </>
  );
}

function MovieGenre({ movie }) {
  const { genres } = useGenres();

  return (
    <Form.FloatingLabel label="Movie genre">
      <Form.Control
        as="select"
        name="genre"
        placeholder="Movie genre"
        className="text-bg-light"
        defaultValue={movie?.genre?.id || ""}
        required
      >
        <option disabled value="">
          --- select ---
        </option>
        {genres.map((genre) => {
          return (
            <option key={genre.slug} value={genre.id}>
              {genre.title}
            </option>
          );
        })}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        Please select movie genre
      </Form.Control.Feedback>
    </Form.FloatingLabel>
  );
}

function MovieCasts({ movie }) {
  const [casts, setCasts] = useState([]);

  useLayoutEffect(() => {
    if (movie?.id) {
      const got = movie?.casts?.map((cast, index) => {
        return { ...cast, id: Date.now().toFixed() + `_${index}` };
      });

      setCasts(() => got);
    } else {
      setCasts([{ id: Date.now().toFixed() }]);
    }
  }, [movie]);

  return (
    <>
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-end"
      >
        <small className="fw-bold">Casts</small>
        <NavLink
          className="text-bg-success px-1"
          onClick={() =>
            setCasts((pre) => [...pre, { id: Date.now().toFixed() }])
          }
        >
          <small>+ Add</small>
        </NavLink>
      </Stack>

      <Stack style={{ maxHeight: "10rem", overflowY: "auto" }}>
        {casts.map((cast, index) => {
          return (
            <Row key={cast.id} className="g-0 mb-2">
              <Col>
                <Form.Control
                  placeholder="Full name"
                  required
                  name={`casts[${index}][name]`}
                  defaultValue={cast?.name}
                  className="text-bg-light border-end-0"
                />
              </Col>

              <Col className="col-4">
                <Form.Control
                  as="select"
                  required
                  name={`casts[${index}][is_director]`}
                  defaultValue={cast?.is_director || ""}
                  className="text-bg-light border-start-0"
                >
                  <option disabled value="">
                    -select role -
                  </option>
                  <option value="false">Cast</option>
                  <option value="true">Director</option>
                </Form.Control>
              </Col>

              <Col className="col-1">
                <NavLink
                  id={cast.id}
                  onClick={() =>
                    casts.length > 1 &&
                    setCasts((pre) => pre.filter((p) => p.id !== cast?.id))
                  }
                  className="text-bg-danger border-0 h-100 w-100 d-flex justify-content-center align-items-center"
                >
                  <MdDelete fontSize="1.2rem" />
                </NavLink>
              </Col>
            </Row>
          );
        })}
      </Stack>
    </>
  );
}

function MovieTags({ movie }) {
  const { tags } = useTags();

  return (
    <>
      <small className="fw-bold">Tags</small>
      <Row
        className="gy-2 me-0"
        style={{ maxHeight: "9.3rem", overflowY: "auto" }}
      >
        {tags.map((tag) => {
          return (
            <Col className="col-6" key={tag.id}>
              <Form.Check
                name="tags[]"
                defaultChecked={
                  movie?.tags?.find
                    ? movie?.tags?.find((d) => d.id === tag.id)
                    : false
                }
                value={tag.id}
                id={tag.title}
                label={tag.title}
                className="text-truncate"
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

function MovieContents({ movie, show }) {
  const [trailers, setTrailers] = useState([]);
  const { contents: data } = useContents({
    enabled: show,
  });

  const { plans } = usePlans({
    enabled: show,
  });

  const contents = useMemo(() => {
    return {
      movies: data.filter((c) => c.category === "movie"),
      trailers: data.filter((c) => c.category === "trailer"),
    };
  }, [data]);

  useLayoutEffect(() => {
    if (movie?.id) {
      const all = movie.trailers?.map((trailer, index) => {
        return { trailer, id: Date.now().toFixed() + `_${index}` };
      });

      setTrailers(all);
    } else {
      setTrailers([{ id: Date.now().toFixed() }]);
    }
  }, [movie]);

  return (
    <>
      <Stack gap={2}>
        <Stack
          direction="horizontal"
          className="justify-content-between align-items-end"
        >
          <small className="fw-bold">Trailers</small>
          <NavLink
            className="text-bg-success px-1"
            onClick={() =>
              setTrailers((pre) => [...pre, { id: Date.now().toFixed() }])
            }
          >
            <small>+ Add</small>
          </NavLink>
        </Stack>

        <Stack style={{ maxHeight: "4rem", overflowY: "auto" }}>
          {trailers.map((trailer) => {
            return (
              <Row key={trailer.id} className="g-0 mb-2">
                <Col>
                  <Form.Control
                    as="select"
                    size="sm"
                    className="text-bg-light"
                    name="trailers[]"
                    required
                    defaultValue={
                      contents.trailers.find((c) => c.id === trailer.trailer)
                        ?.id || ""
                    }
                  >
                    <option disabled value="">
                      --- select ---
                    </option>
                    {contents.trailers.map((content) => {
                      return (
                        <option key={content?.id} value={content.id}>
                          {content?.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>

                <Col className="col-1">
                  <NavLink
                    id={trailer.id}
                    onClick={() =>
                      trailers.length > 1 &&
                      setTrailers((pre) =>
                        pre.filter((p) => p.id !== trailer?.id)
                      )
                    }
                    className="text-bg-danger border-0 h-100 w-100 d-flex justify-content-center align-items-center"
                  >
                    <MdDelete fontSize="1.2rem" />
                  </NavLink>
                </Col>
              </Row>
            );
          })}
        </Stack>
      </Stack>

      <Stack gap={2}>
        <small className="fw-bold">Contents</small>
        {plans.map((plan, index) => {
          return (
            <Form.FloatingLabel
              key={plan.id}
              label={`Movie ${plan.slug.replace("-", "")} content`}
            >
              <Form.Control
                type="hidden"
                defaultValue={plan?.id}
                name={`content[${index}][plan]`}
              />
              <Form.Control
                as="select"
                name={`content[${index}][link]`}
                className="text-bg-light"
                defaultValue={
                  movie?.content?.find((c) => c.plan === plan?.id)?.link || ""
                }
                required
              >
                <option disabled value="">
                  --- select ---
                </option>
                {contents.movies.map((content) => {
                  return (
                    <option
                      key={`movie_content${content.id}`}
                      value={content.id}
                    >
                      {content.title}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.FloatingLabel>
          );
        })}
      </Stack>
    </>
  );
}

export default AddEditMovie;
