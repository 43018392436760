import { createContext, useReducer } from "react";
import { AppStore } from "../imports/utils";

export const AuthContext = createContext();

const defaultValue = {
  user: {
    created_at: "",
    email: "",
    full_name: "",
    img_url: "",
    last_logged: "",
    phone: null,
    updated_at: "",
  },
  authenticated: false,
};

export default function AuthContextProvider({ children }) {
  const logged = AppStore.getData("auth", AppStore.path.session);
  const [state, dispatch] = useReducer(authContextReducer, {
    user: logged?.user || defaultValue?.user,
    authenticated: logged ? true : defaultValue.authenticated,
  });

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

function authContextReducer(state, { payload = {}, type = "" }) {
  switch (type) {
    case "LOGIN":
      AppStore.setData("auth", payload, AppStore.path.session);
      return {
        user: payload.user,
        authenticated: true,
      };
    case "LOGOUT":
      AppStore.unSetData("auth", AppStore.path.session);
      return {
        defaultValue,
      };
    default:
      return state;
  }
}
