import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditMovie, DataTable, Toolbar } from "../imports/components";
import { useMovies } from "../imports/hooks";

function Movies() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [movies, setMovies] = useState([]);

  const { movies: data, errorMessage: message } = useMovies();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setMovies(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Movies"
        dataKey="movies"
        onSearch={setMovies}
        onAddClick={handleAddViewEdit}
        filters={["name"]}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={movies}
        onRecordClick={handleAddViewEdit}
        columns={[
          ["Title", ["title", "text"]],
          ["Genre", ["genre", "slug"]],
          ["Maturity", ["maturity", "age"]],
          "Creator",
        ]}
      />

      <AddEditMovie
        show={show}
        movie={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Movies;
