import API, { baseURL, resPath } from "../utils/api";
import AppStore from "../utils/storage";

export const args = {
  dialog: {
    data: {},
    show: false,
    manager: {},
    hide: () => {},
  },
  manage: {
    id: null,
    enabled: true,
    clientKey: null,
    onSuccess: () => true,
  },
};

export function processData(event, arrays = []) {
  const data = new FormData(event.target);

  if (arrays?.length > 0) {
    return formDataToArray(data, arrays);
  }

  return data;
}

export function formDataToArray(data, keys = [], separator = ",") {
  keys.forEach((key) => {
    const key_arr = `${key}[]`;
    const val = data.get(key);
    data.delete(key);

    if (val) {
      if (val.indexOf(separator) > -1) {
        val
          .split(separator)
          .forEach((v, i) => data.set(`${key}[${i}]`, v.trim()));
      } else {
        data.set(key_arr, val);
      }
    }
  });

  return data;
}

export function toArray(val, add = 0) {
  const arr = Number(val) + add;
  return Array.from(Array(arr));
}

export { API, AppStore, baseURL, resPath };
