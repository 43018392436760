import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Title() {
  const { pathname } = useLocation();
  const [title, setTitle] = useState("/");
  const [current, setCurrent] = useState("/");

  const capitalise = (word, char = "-") => {
    if (word.indexOf(char) > -1) {
      const words = word.split(char);
      word = "";
      words.forEach(
        (w) => (word += ` ${w.charAt(0).toUpperCase()}${w.slice(1, w.length)}`)
      );
    } else {
      word = `${word.charAt(0).toUpperCase()}${word.slice(1, word.length)}`;
    }

    return word.trim();
  };

  useEffect(() => {
    let path = pathname.replace("/admin", "");

    if (path.indexOf("/")) {
      path = path.split("/");
      path = path[path.length - 1];
    }

    if (path.length < 1) {
      path = "/dashboard";
    }

    setCurrent(path);
    setTitle(capitalise(path));

    return () => {};
  }, [pathname]);

  return { title, current };
}
