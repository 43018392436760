import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditCategory, DataTable, Toolbar } from "../imports/components";
import { useCategories } from "../imports/hooks";

function Categories() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [categories, setCategories] = useState([]);
  const { categories: data, errorMessage: message } = useCategories();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setCategories(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Categories"
        dataKey="categories"
        onSearch={setCategories}
        onAddClick={handleAddViewEdit}
        filters={["title", "slug", "description"]}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={categories}
        onRecordClick={handleAddViewEdit}
        columns={["Title", "Slug", ["Data Type", "data_type"]]}
      />

      <AddEditCategory
        show={show}
        category={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Categories;
