import { useMemo } from "react";
import { API } from "../imports/utils";
import { usePost, useUpdate } from "../imports/hooks";

const args = {
  id: "",
  isAll: true,
  enabled: false,
  postPath: null,
  clientKey: null,
  updatePath: null,
  path: "managers",
  isMultipart: false,
  onSuccess: () => true,
};

export default function useManagers(props = { ...args }) {
  props = { ...args, ...props };

  const { data, isLoading } = API.Get({
    key: props.clientKey,
    enabled: props.enabled,
    path: `${props.path}${props.isAll ? "/all" : ""}`,
  });

  const values = useMemo(() => {
    if (isLoading) return [];

    return data?.message ? [] : data;
  }, [data, isLoading]);

  const { post, state, processing, isValidated } = usePost(
    props.postPath ?? props.path,
    {
      successCode: 201,
      toggleOnSuccess: true,
      isMultipart: props.isMultipart,
      clientKey: props.clientKey ?? props.path,
      onSuccess: (res) => props.onSuccess(res),
    }
  );

  const { update, canUpdate, updated, updating } = useUpdate(
    props.id,
    props.updatePath ?? props.path,
    {
      id: props.id,
      successCode: 200,
      toggleOnSuccess: true,
      isMultipart: props.isMultipart,
      clientKey: props.clientKey ?? props.path,
      onSuccess: (res) => props.onSuccess(res),
    }
  );

  return {
    updated,
    updating,
    canUpdate,
    data: values,
    create: post,
    update: update,
    created: state,
    fetching: isLoading,
    creating: processing,
    canCreate: isValidated,
    state: state || updated,
    errorMessage: data?.message,
    validated: isValidated || canUpdate,
    processing: isLoading || processing || updating,
  };
}
