import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditPlan, DataTable, Toolbar } from "../imports/components";
import { usePlans } from "../imports/hooks";

function Plans() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [plans, setPlans] = useState([]);

  const { plans: data, errorMessage: message } = usePlans();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setPlans(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Plans"
        dataKey="plans"
        onSearch={setPlans}
        onAddClick={handleAddViewEdit}
        filters={["title", "slug"]}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={plans}
        onRecordClick={handleAddViewEdit}
        columns={["Title", "Slug"]}
      />

      <AddEditPlan
        show={show}
        plan={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Plans;
