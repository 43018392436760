import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditEndpoint, DataTable, Toolbar } from "../imports/components";
import { useEndpoints } from "../imports/hooks";

function Endpoints() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [endpoints, setEndpoints] = useState([]);

  const { endpoints: data, errorMessage: message } = useEndpoints();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setEndpoints(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        title="Endpoints"
        printRef={ref}
        dataKey="endpoints"
        onSearch={setEndpoints}
        filters={["title", "slug"]}
        onAddClick={handleAddViewEdit}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={endpoints}
        columns={["Title", "Slug"]}
        onRecordClick={handleAddViewEdit}
      />

      <AddEditEndpoint
        show={show}
        endpoint={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Endpoints;
