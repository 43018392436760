import React, { useLayoutEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  BroadcastMessage,
  DataTable,
  ReviewSubscriber,
  Toolbar,
} from "../imports/components";
import { useSubscribers } from "../imports/hooks";

function Subscribers() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [subscribers, setSubscribers] = useState([]);
  const [broadcast, setBroadcast] = useState(false);
  const {
    renew,
    to_renew,
    processing,
    subscribers: data,
    errorMessage: message,
  } = useSubscribers({
    onSuccess,
  });

  function onSuccess(res) {
    toast(res?.message, {
      hideProgressBar: true,
      type: res.success ? "success" : "error",
    });

    return false;
  }

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setSubscribers(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        dataKey="subscribers"
        title="Subscribers"
        canCreate={false}
        addText="Invite New"
        onSearch={setSubscribers}
        filters={["full_name", "payment_type", "phone", "plan", "phone"]}
        onAddClick={handleAddViewEdit}
      >
        {to_renew > 0 && (
          <Button
            disabled={processing}
            type="button"
            size="sm"
            variant="warning"
            onClick={renew}
          >
            {processing ? "Wait...!" : <>Renew ({to_renew})</>}
          </Button>
        )}
      </Toolbar>

      <DataTable
        dataRef={ref}
        message={message}
        records={subscribers}
        actionText="Review"
        columns={[
          ["Full Name", "full_name"],
          "Phone",
          ["Continent", ["geo_location", "continentCode"]],
          ["Plan", ["plan", "title"]],
          ["Paid By", "payment_type"],
          ["Expired", "expired"],
          ["Canceled", "is_canceled"],
        ]}
        onRecordClick={handleAddViewEdit}
      />

      <ReviewSubscriber
        show={show}
        subscriber={selected}
        hide={() => handleAddViewEdit({}, false)}
      />

      <BroadcastMessage
        data={subscribers}
        show={broadcast}
        hide={() => setBroadcast(false)}
        filterKey="first_name"
        filters={[
          { title: "Subscribers", value: "subs" },
          { title: "None-subscribers", value: "none-subs" },
        ]}
      />
    </>
  );
}

export default Subscribers;
