import { useMemo } from "react";
import { API, args } from "../imports/utils";
import { usePost, useUpdate } from "../imports/hooks";

export default function useMethods(props = { ...args.manage }) {
  props = { ...args.manage, ...props, clientKey: "methods", path: "methods" };

  const { data, isLoading } = API.Get({
    key: props.clientKey,
    path: `${props.path}/all`,
    enabled: props.enabled,
  });

  const methods = useMemo(() => {
    if (isLoading) return [];

    return data?.message ? [] : data;
  }, [data, isLoading]);

  const { post, state, processing, isValidated } = usePost(props.clientKey, {
    successCode: 201,
    isMultipart: true,
    toggleOnSuccess: true,
    clientKey: props.clientKey,
    onSuccess: (res) => props.onSuccess(res),
  });

  const { update, canUpdate, updated, updating } = useUpdate(
    props.id,
    props.clientKey,
    {
      successCode: 200,
      isMultipart: true,
      toggleOnSuccess: true,
      clientKey: props.clientKey,
      onSuccess: (res) => props.onSuccess(res),
    }
  );

  return {
    methods,
    create: post,
    update: update,
    state: state || updated,
    errorMessage: data?.message,
    validated: isValidated || canUpdate,
    processing: isLoading || processing || updating,
  };
}
