import { useContext } from "react";
import { FileContext } from "../contexts/file-context";

export default function ImagePreviewHook() {
  const context = useContext(FileContext);

  if (!context) throw Error("FileContext cannot be used outside of it's scope");

  return context;
}
