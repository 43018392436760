import React from "react";
import { Button, Image, NavLink, Table } from "react-bootstrap";
import { useFilePreview } from "../../imports/hooks";
import { resPath } from "../../utils/api";

const attrs = {
  dataRef: null,
  className: "",
  columns: [],
  records: [],
  message: "",
  children: null,
  tableClassName: "",
  actionText: "View/Edit",
  onRecordClick: () => {},
};

const DataTable = (params = { ...attrs }) => {
  const props = { ...attrs, ...params };
  const { dispatch } = useFilePreview();

  function actionClick(record) {
    props?.onRecordClick(record);
  }

  function handleObjectClick(data) {
    dispatch({
      type: "SHOW",
      payload: {
        path: data,
      },
    });
  }

  function getColData(record, col) {
    let data = null;

    if (typeof col === "string") {
      data = record?.[col.toLowerCase().replace(" ", "_")];
    } else {
      data = record?.[col[col.length - 1]];

      if (data === undefined) {
        data = record?.[col[0].toLowerCase()]?.[col[col.length - 1]];
        if (data === undefined) {
          const sub_col = col[col.length - 1];
          data = getColData(record, sub_col);
        }
      }

      if (typeof data === "boolean") {
        data = data ? "Yes" : "No";
      }
    }

    if (data?.indexOf && data?.indexOf("/") > -1 && !data?.startsWith("/")) {
      const path = `${resPath}/${data}`;
      data = (
        <NavLink onClick={() => handleObjectClick(path)}>
          <Image src={path} width={35} className="image" />
        </NavLink>
      );
    }

    return data ? data : "N/A";
  }

  return (
    <div ref={props?.dataRef} className={props?.className}>
      <Table
        responsive={true}
        className={`text-dark w-100 ${props?.tableClassName}`}
      >
        <thead>
          <tr>
            {["#ID", ...props?.columns, ""].map((col, index) => {
              return (
                <th key={`header_${index}`}>
                  {typeof col === "string" ? col : col[0]}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {props.records?.length > 0 && props.records?.map ? (
            props.records?.map((record, index) => {
              return (
                <tr key={`record_${index}`}>
                  <td className="text-center" style={{ width: "3rem" }}>
                    {index + 1}.
                  </td>
                  {props?.columns.map((col, key) => {
                    return (
                      <td key={`record_${index}_${key}`}>
                        {getColData(record, col)}
                      </td>
                    );
                  })}
                  {params?.children}
                  <td className="text-end">
                    <Button
                      size="sm"
                      variant="info"
                      className="py-0"
                      onClick={() => actionClick(record)}
                    >
                      <small>{props?.actionText}</small>
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={props.columns.length + 2}
                className="text-center text-muted"
              >
                <em>
                  {props.message?.length > 0
                    ? props.message
                    : "No content found"}
                </em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTable;
