import React, { useRef } from "react";
import { Alert, Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import { useBanners, useConfirmDelete, useMovies } from "../../imports/hooks";
import { ImageSelect } from "../../imports/components";
import SubmitButton from "../custom/SubmitButton";
import { args } from "../../imports/utils";

const AddEditBanner = (props = { ...args.dialog }) => {
  const ref = useRef();

  const { movies } = useMovies();
  const { dispatch } = useConfirmDelete();
  const { banner, hide, show } = { ...args.dialog, ...props };
  const { create, update, processing, state, validated } = useBanners({
    onSuccess,
    id: banner?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (res?.success && !banner?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        onHide: hide,
        key: "banners",
        path: "banners",
        value: { id: banner?.id, name: banner?.name },
      },
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {banner?.id ? "Edit" : "Add"} Banner
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={banner?.id ? update : create}
        >
          <Stack gap={3} className="mb-3">
            <Row className="gx-0 align-items-start">
              <Col>
                <ImageSelect name="image" title="image" src={banner?.image} />
              </Col>
              <Col className="col-9">
                <Form.FloatingLabel label="Movie" className="mb-2">
                  <Form.Control
                    as="select"
                    name="movie_id"
                    type="text"
                    placeholder="Movie"
                    className="text-bg-light"
                    defaultValue={banner?.movie?.id || ""}
                    required
                    autoFocus
                  >
                    <option disabled value="">
                      --- select ---
                    </option>
                    {movies?.map &&
                      movies.map((movie) => {
                        return (
                          <option key={movie.id} value={movie.id}>
                            {movie.title.text}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a movie
                  </Form.Control.Feedback>
                </Form.FloatingLabel>

                <Form.FloatingLabel label="Show on banner">
                  <Form.Control
                    as="select"
                    name="can_show"
                    type="text"
                    placeholder="Show on banner"
                    className="text-bg-light"
                    defaultValue={banner?.can_show || ""}
                    required
                    autoFocus
                  >
                    <option disabled value="">
                      --- select ---
                    </option>
                    <option value={false}>No, do not show</option>
                    <option value={true}>Yes, do show</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a banner name
                  </Form.Control.Feedback>
                </Form.FloatingLabel>
              </Col>
            </Row>

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {banner?.id ? "Update" : "Create"}
              </SubmitButton>

              {banner?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditBanner;
