import React from "react";
import { Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const Authenticate = () => {
  return (
    <div className="login-bg text-black">
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Outlet />
        </Row>
      </Container>
    </div>
  );
};

export default Authenticate;
