import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useConfirmDelete, useDelete } from "../../imports/hooks";

const ConfirmDelete = () => {
  const client = useQueryClient();
  const { show, path, param, key, value, onHide, dispatch } =
    useConfirmDelete();

  const { remove, removed, removing } = useDelete(value?.id, path, {
    toggleOnSuccess: true,
    onSuccess: (res) => onSuccess(res),
  });

  const onSuccess = (res) => {
    if (res?.success) {
      onHide();
      if (typeof key === "string") {
        client.invalidateQueries(key);
      } else {
        key.forEach((k) => {
          client.invalidateQueries(k);
        });
      }

      toast.success(
        <small>{`${value?.name} deleted from ${path} successfully`}</small>,
        {
          hideProgressBar: true,
        }
      );

      hide();

      return false;
    }

    return true;
  };

  function hide() {
    dispatch({
      type: "HIDE",
      payload: null,
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      keyboard={removed?.success}
      backdropClassName="opacity-0"
      dialogClassName="text-secondary bg-secondary"
      contentClassName="px-2"
      backdrop={removed?.success ? "static" : undefined}
    >
      <Modal.Header className="border-0 pb-0">
        <strong className="lead">
          {removed?.success ? (
            <strong className="text-success">Success</strong>
          ) : (
            <h5 className="fw-bold m-0">Confirm Delete</h5>
          )}
        </strong>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete <strong>{value?.name}</strong>?
        </p>
        {removed && <Alert variant="danger">{removed.message}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" className="border" onClick={hide}>
          {removed?.success ? "Close" : "Cancel"}
        </Button>
        {!removed?.success && (
          <Button
            onClick={() => {
              remove(param, value?.id);
            }}
            variant="danger"
          >
            {removing ? "Deleting..." : "Delete"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDelete;
