import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditAdmin, DataTable, Toolbar } from "../imports/components";
import { useAdmins } from "../imports/hooks";

function Admins() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selected, setSelected] = useState({});

  const { admins: data, errorMessage: message } = useAdmins();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setAdmins(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Admins"
        dataKey="admins"
        addText="Invite New"
        onSearch={setAdmins}
        filters={["full_name", "phone", "email", "role"]}
        onAddClick={handleAddViewEdit}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={admins}
        columns={[["Full Name", "full_name"], "Phone", "Email", "Role"]}
        onRecordClick={handleAddViewEdit}
      />

      <AddEditAdmin
        show={show}
        admin={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Admins;
