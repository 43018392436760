import React, { useLayoutEffect, useState } from "react";
import { Card, Col, Row, Stack, Table } from "react-bootstrap";
import Chart from "react-google-charts";
import { API } from "../../imports/utils";
import { useMemo } from "react";

const AccountsCard = () => {
  const { data } = API.Get({
    key: "chart-data",
    path: "static/chart-data",
  });

  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);

  const by_accounts = useMemo(
    () => (data?.by_accounts?.map ? data?.by_accounts : []),
    [data?.by_accounts]
  );

  useLayoutEffect(() => {
    if (data?.by_subscriptions?.map) {
      setHeaders(data.by_subscriptions[0]);
      setRows(data.by_subscriptions.slice(1));
    }
  }, [data]);

  return (
    <div className="dashboard">
      <Row className="gy-3 mb-5">
        <h5 className="mb-0">Accounts per region</h5>
        {by_accounts.length > 0 ? (
          by_accounts.map((val) => {
            return (
              <Col xl={2} sm={4} md={3} className="col-6" key={val.abbr}>
                <Card className="position-relative text-bg-primary">
                  <span className="water-mark display-2">{val.abbr}</span>
                  <Card.Body className="pb-0">
                    <p className="text-truncate fw-bolder text-uppercase m-0">
                      <small>{val.name}</small>
                    </p>
                    <Stack className="align-items-end">
                      <p className="lead m-0 display-5 mb-0">
                        {Intl.NumberFormat().format(val.total)}
                      </p>
                      <small>accounts</small>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        ) : (
          <p className="lead text-muted m-0">
            <em>No content found</em>
          </p>
        )}
      </Row>

      <Row className="my-5 gy-4">
        <Col lg={8}>
          <Card className="border-0 shadow">
            <Card.Body>
              <p className="mb-0 fw-bold text-secondary">
                Subscriber per region per plan
              </p>
              {data?.by_subscriptions?.length > 0 ? (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={data.by_subscriptions}
                />
              ) : (
                <p className="lead text-muted">
                  <em>No content found</em>
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card
            className="shadow border-0 w-100 h-100"
            style={{ maxHeight: "100%" }}
          >
            <Card.Body>
              <p className="mb-0 fw-bold text-secondary">Summary</p>
              <Table responsive className="text-bg-light">
                <thead>
                  <tr>
                    {headers.map((header, index) => {
                      return (
                        <th
                          className={index > 0 ? "text-center" : ""}
                          key={`header_${index + 1}`}
                        >
                          <small>{header}</small>
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {rows.map((row, index) => {
                    return (
                      <tr key={`row_${index + 1}`}>
                        {row.map &&
                          row.map((col, i) => {
                            return (
                              <td
                                key={`col_${index}_${i}`}
                                className={i > 0 ? "text-center" : ""}
                              >
                                <small>{col}</small>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AccountsCard;
