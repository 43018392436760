import React, { useLayoutEffect, useRef, useState } from "react";
import { useBanners } from "../imports/hooks";
import { AddEditBanner, DataTable, Toolbar } from "../imports/components";

function Banners() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [banners, setBanners] = useState([]);
  const [selected, setSelected] = useState({});
  const { banners: data, errorMessage: message } = useBanners();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setBanners(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Banners"
        dataKey="banners"
        onSearch={setBanners}
        onAddClick={handleAddViewEdit}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={banners}
        onRecordClick={handleAddViewEdit}
        columns={[
          ["Banner", "image"],
          ["Poster", ["movie", "poster"]],
          "Title",
          ["Showing", "can_show"],
        ]}
      />

      <AddEditBanner
        show={show}
        banner={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Banners;
