import React from "react";
import { Alert, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { Link, SubmitButton } from "../imports/components";
import { usePost } from "../imports/hooks";
import { BiCheckCircle } from "react-icons/bi";

function Register() {
  const { state, post, data, isValidated, processing } = usePost("admins", {
    toggleOnSuccess: true,
    isMultipart: true,
  });

  return (
    <div className="login-bg">
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col xxl={4} lg={5} md={7} sm={9}>
            <Card className="login-card shadow border-0">
              <Card.Body className="px-4 pt-3 pb-0">
                {data && data?.success ? (
                  <Stack className="text-center">
                    <p className="mb-3">
                      <BiCheckCircle className="text-primary display-1" />
                    </p>
                    <p className="text-secondary fs-5 fw-bold mb-0">
                      Account created successfully
                    </p>
                    <p>
                      <small>You can now login into your new account</small>
                    </p>

                    <Stack
                      direction="horizontal"
                      className="justify-content-center"
                    >
                      <Link
                        href="/"
                        className="px-4 py-2 text-bg-primary rounded"
                      >
                        Goto to login
                      </Link>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <h2 className="fw-bolder text-primary">Register</h2>
                    <p className="m-0">
                      Create a new back office account for Sierraflixx
                    </p>

                    <Form
                      noValidate
                      validated={isValidated}
                      className="w-100 my-4"
                      onSubmit={post}
                    >
                      <Stack gap={3} className="mb-4">
                        <Stack>
                          <p className="fw-bold mb-0">Verify OTP</p>
                          <Form.FloatingLabel label="OTP Code">
                            <Form.Control
                              name="invite_code"
                              type="number"
                              placeholder="OTP Code"
                              className="text-bg-light"
                              maxLength={6}
                              required
                              autoFocus
                            />

                            <Form.Control.Feedback type="invalid">
                              OTP code is required
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>
                          <small>
                            Enter the code sent to your number as invite
                          </small>
                        </Stack>

                        <Stack>
                          <p className="fw-bold mb-0">Account Details</p>
                          <Form.FloatingLabel
                            label="Full Name"
                            className="mb-2"
                          >
                            <Form.Control
                              name="full_name"
                              type="text"
                              placeholder="Full Name"
                              className="text-bg-light"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide your name
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>

                          <Form.FloatingLabel label="Email" className="mb-2">
                            <Form.Control
                              name="email"
                              type="email"
                              placeholder="Email"
                              className="text-bg-light"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid email
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>

                          <Form.FloatingLabel label="Password" className="mb-2">
                            <Form.Control
                              name="secret"
                              type="password"
                              placeholder="Password"
                              className="text-bg-light"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a new password
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>

                          <Form.Group>
                            <Form.Label htmlFor="image">
                              <small className="fw-bold">
                                Choose your photo
                              </small>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              id="image"
                              name="photo"
                              accept="image/*"
                              className="bg-light text-secondary"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please choose your profile photo
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Stack>

                        {state && (
                          <Alert variant="danger">{state.message}</Alert>
                        )}
                      </Stack>

                      <Stack gap={2}>
                        <SubmitButton isProcessing={processing} size="lg">
                          SUBMIT
                        </SubmitButton>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="justify-content-center"
                        >
                          Already registered?
                          <Link href="/" className="text-info">
                            Login
                          </Link>
                        </Stack>
                      </Stack>
                    </Form>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
