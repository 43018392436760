import { useContext } from "react";

import { ConfirmDeleteContext } from "../imports/context";

export default function ConfirmDelete() {
  const context = useContext(ConfirmDeleteContext);

  if (!context)
    throw Error("ConfirmDeleteContext cannot be used outside of it's scope");

  return context;
}
