import React from "react";
import {
  Col,
  Form,
  FormControl,
  FormFloating,
  FormLabel,
  Row,
  Stack,
} from "react-bootstrap";
import { useDevices, useCategories } from "../../../imports/hooks";

const PlanMeta = ({ plan }) => {
  const { devices } = useDevices();
  const { categories } = useCategories();

  function getFormField(category, val, key, end = "") {
    const name = `meta[${category.slug}]${key ? "[" + end + "]" : ""}`;
    return (
      <FormFloating key={key || category.id}>
        <FormControl
          name={name}
          placeholder={`${category.title} ${end} `.trim()}
          className="text-bg-light"
          defaultValue={val || plan?.meta?.[category.slug]}
          required
        />
        <FormLabel>{`${category.title} ${end} `.trim()}</FormLabel>
      </FormFloating>
    );
  }

  function processCategory(category) {
    switch (category.data_type) {
      case "string":
        return getFormField(category);
      case "array":
        if (category.slug === "devices") {
          return (
            <Row key={category.id}>
              <p className="mb-0 text-muted">Devices</p>
              {devices?.map &&
                devices.map((device) => {
                  return (
                    <Col key={device.id}>
                      <Form.Check
                        name="meta[devices][]"
                        defaultChecked={
                          plan?.meta?.devices?.find(
                            (d) => d?.id === device.id
                          ) !== undefined
                        }
                        value={device.id}
                        id={device.name}
                        label={device.name}
                        className="w-100"
                      />
                    </Col>
                  );
                })}
            </Row>
          );
        }
        break;
      case "object":
        return (
          <Stack key={category.id} gap={2}>
            {Object.values(category?.props).map((prop) => {
              return getFormField(
                category,
                plan?.meta?.[category.slug]?.[prop] || "",
                `${category.id}_${prop}`,
                prop
              );
            })}
          </Stack>
        );
      default:
        return (
          <p key={category.id} className="lead m-0">
            Invalid type
          </p>
        );
    }
  }

  return (
    <Stack gap={2}>
      {categories.map((category) => {
        return processCategory(category);
      })}
    </Stack>
  );
};

export default PlanMeta;
