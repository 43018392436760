import React from "react";
import { Alert, Card, Col, Form, Stack } from "react-bootstrap";
import { Link, SubmitButton } from "../imports/components";
import { usePost } from "../imports/hooks";

function ForgotPassword() {
  const { state, post, processing, isValidated } = usePost(
    "admins/request-otp",
    {
      errorInterval: 6000,
      toggleOnSuccess: false,
    }
  );

  return (
    <Col xxl={4} lg={5} md={7} sm={9}>
      <Card className="login-card shadow border-0">
        <Card.Body className="px-4 py-5">
          <h2 className="fw-bolder text-primary">Forgot Password</h2>
          <p className="m-0">Provide your phone number to reset password</p>

          <Form
            noValidate
            validated={isValidated}
            className="w-100 my-4"
            onSubmit={post}
          >
            <Form.Control
              type="hidden"
              value="forgot password"
              name="purpose"
            />
            <Stack gap={2} className="mb-4">
              <Form.FloatingLabel label="Phone number">
                <Form.Control
                  name="phone"
                  type="number"
                  placeholder="Phone number"
                  className="text-bg-light"
                  required
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a phone number
                </Form.Control.Feedback>
              </Form.FloatingLabel>

              {state && <Alert variant="danger">{state.message}</Alert>}
            </Stack>

            <Stack gap={2}>
              <SubmitButton isProcessing={processing} size="lg">
                SUBMIT
              </SubmitButton>
              <Stack
                direction="horizontal"
                gap={2}
                className="justify-content-center"
              >
                Remembered password?
                <Link href="/" className="text-info">
                  Login
                </Link>
              </Stack>
            </Stack>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default ForgotPassword;
