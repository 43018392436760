import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditDevice, DataTable, Toolbar } from "../imports/components";
import { useDevices } from "../imports/hooks";

function Devices() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [devices, setDevices] = useState([]);

  const { devices: data, errorMessage: message } = useDevices();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setDevices(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        title="Devices"
        dataKey="devices"
        onSearch={setDevices}
        onAddClick={handleAddViewEdit}
        filters={["name"]}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={devices}
        onRecordClick={handleAddViewEdit}
        columns={["Image", "Name", "Slug"]}
      />

      <AddEditDevice
        show={show}
        device={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Devices;
