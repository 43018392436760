import React, { useLayoutEffect, useRef, useState } from "react";
import { AddEditPrivilege, DataTable, Toolbar } from "../imports/components";
import { usePrivileges } from "../imports/hooks";

function Privileges() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [privileges, setPrivileges] = useState([]);
  const { privileges: data, errorMessage: message } = usePrivileges();

  function handleAddViewEdit(record = {}, show = true) {
    setShow(show);
    setSelected(record);
  }

  useLayoutEffect(() => setPrivileges(data ? data : []), [data]);

  return (
    <>
      <Toolbar
        data={data}
        printRef={ref}
        dataKey="privileges"
        title="Admin Privileges"
        onSearch={setPrivileges}
        filters={["title", "slug"]}
        onAddClick={handleAddViewEdit}
      />

      <DataTable
        dataRef={ref}
        message={message}
        records={privileges}
        columns={[
          ["Role", "title"],
          ["Endpoint", "title"],
          ["Create", "can_create"],
          ["Read", "can_read"],
          ["Update", "can_update"],
          ["Delete", "can_delete"],
        ]}
        onRecordClick={handleAddViewEdit}
      />

      <AddEditPrivilege
        show={show}
        privilege={selected}
        hide={() => handleAddViewEdit({}, false)}
      />
    </>
  );
}

export default Privileges;
