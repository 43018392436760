import React, { useRef, useState } from "react";
import { Alert, Form, Modal, Stack } from "react-bootstrap";
import { usePost } from "../../imports/hooks";
import SubmitButton from "../custom/SubmitButton";

const BroadcastMessage = ({ data, filterKey, show, hide, filters = [] }) => {
  const ref = useRef();
  const [phones, setPhones] = useState([]);
  const { post, state, processing, isValidated } = usePost(
    "static/broadcast-message",
    {
      successCode: 201,
      toggleOnSuccess: true,
    }
  );

  function handleFilter({ target }) {
    let fils = data;
    switch (target.value) {
      case "subs":
        fils = data.filter((a) => a?.[filterKey] !== null);
        break;
      case "non-subs":
        fils = data.filter((a) => a?.[filterKey] === null);
        break;
      case "all":
        fils = data;
        break;
      default:
        fils = [];
        break;
    }

    setPhones(fils.map((d) => d.phone));
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">Broadcast Message</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          onSubmit={post}
          validated={isValidated}
          className="mb-3"
        >
          <Stack gap={4}>
            <Stack gap={3}>
              <Form.FloatingLabel label="Type your message">
                <Form.Control
                  name="message"
                  type="text"
                  as="textarea"
                  placeholder="Type your message"
                  className="text-bg-light"
                  required
                  autoFocus
                  style={{ height: "96px" }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the message to send
                </Form.Control.Feedback>
              </Form.FloatingLabel>

              <Form.FloatingLabel label="Broadcast to">
                <Form.Control
                  type="text"
                  as="select"
                  className="text-bg-light"
                  defaultValue=""
                  required
                  onChange={handleFilter}
                >
                  <option disabled value="">
                    --- select ---
                  </option>
                  {filters?.map &&
                    [{ title: "All", value: "all" }, ...filters].map(
                      (filter, index) => {
                        return (
                          <option key={`filter_${index}`} value={filter.value}>
                            {filter.title}
                          </option>
                        );
                      }
                    )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select receivers of the broadcast
                </Form.Control.Feedback>
              </Form.FloatingLabel>

              <div>
                <small>This broadcast will go to:</small>
                <Form.Control
                  type="text"
                  name="phones"
                  required
                  as="textarea"
                  className="text-bg-light"
                  defaultValue={phones?.length > 0 ? phones?.join(", ") : ""}
                />
                <Form.Control.Feedback type="invalid">
                  Ensure the list has phones to message
                </Form.Control.Feedback>
              </div>
            </Stack>

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}

            <SubmitButton
              isProcessing={processing}
              size="lg"
              variant="secondary"
            >
              Broadcast Message
            </SubmitButton>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BroadcastMessage;
