import { createContext, useReducer } from "react";

const defaultValue = {
  show: false,
  path: "/",
  fileType: "image",
};

export const FileContext = createContext();

export default function FileContextProvider({ children }) {
  const [state, dispatch] = useReducer(FileContextReducer, defaultValue);

  return (
    <FileContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FileContext.Provider>
  );
}

function FileContextReducer(state, action) {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        show: true,
        ...action.payload,
      };
    case "HIDE":
      return defaultValue;

    default:
      return state;
  }
}
