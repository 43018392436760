import { useQuery } from "react-query";

import axios from "axios";
import storage from "./storage";

const remote = "https://sierraflixx.net/api";

export const resPath =
  process.env.NODE_ENV === "development" ? "/www" : `${remote}/www`;
export const baseURL =
  process.env.NODE_ENV === "development" ? "/backend/" : `${remote}/backend`;
const auth = storage.getData("auth", storage.path.session);

const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${auth?.token}`,
  },
});

const args = {
  get: {
    path: "",
    param: null,
    value: null,
    key: null,
    init: [],
    enabled: true,
  },
  post: {
    path: "",
    data: null,
    onProgress: () => {},
  },
  update: {
    id: null,
    path: "",
    data: null,
    onProgress: () => {},
  },
  delete: {
    id: null,
    path: "",
  },
};

function Get(props = { ...args.get }) {
  props = { ...args.get, ...props };

  async function get() {
    let url = props.path;

    if (props.param) {
      url += `?param=${props?.param}`;

      if (props.value) {
        url += `&value=${props.value}`;
      }
    }

    return await api
      .get(url)
      .then((res) => res.data)
      .catch(({ response }) => response);
  }

  return useQuery(props.key ?? props.path, get, {
    staleTime: 300,
    enabled: props.enabled,
    placeholderData: props.init,
    refetchOnWindowFocus: false,
  });
}

async function PostForm(props = { ...args.post }) {
  props = { ...args.post, ...props };

  return await api
    .post(props.path, props.data, {
      onUploadProgress: (res) => props.onProgress(res),
    })
    .then((res) => res.data)
    .catch(({ response }) => response);
}

async function Post(props = { ...args.post }) {
  props = { ...args.post, ...props };

  return await api
    .post(props.path, props.data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data)
    .catch(({ response }) => response);
}

async function Update(props = { ...args.update }) {
  props = { ...args.update, ...props };

  let url = `${props.path}?id=${props.id}`;

  return await api
    .patch(url, props.data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data)
    .catch(({ response }) => response);
}

async function UpdateForm(props = { ...args.update }) {
  props = { ...args.update, ...props };

  let url = `${props.path}/update?id=${props.id}`;

  return await api
    .post(url, props.data, {
      onUploadProgress: (res) => props.onProgress(res),
    })
    .then((res) => res.data)
    .catch(({ response }) => response);
}

async function Delete(props = { ...args.delete }) {
  props = { ...args.delete, ...props };

  let url = `${props?.path}?id=${props?.id}`;

  return await api
    .delete(url)
    .then((res) => res.data)
    .catch(({ response }) => response);
}

const API = {
  Get,
  Post,
  Delete,
  Update,
  PostForm,
  UpdateForm,
};

export default API;
