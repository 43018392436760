import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { FileContextProvider } from "../imports/context";
import {
  ConfirmDelete,
  FilePreview,
  Link,
  Sidebar,
} from "../imports/components";
import { useAuth, useTitle } from "../imports/hooks";
import { LogoIcon } from "../imports/images";
import { resPath } from "../utils/api";
import { Image } from "react-bootstrap";

const AdminPanel = () => {
  const { current } = useTitle();
  const [showSidebar, setShowSidebar] = useState(true);
  const { authenticated, user, dispatch } = useAuth();

  function handleLogout(e) {
    e.preventDefault();

    dispatch({
      type: "LOGOUT",
      payload: null,
    });
  }

  return !authenticated ? (
    <Navigate to="/" replace={true} state={null} />
  ) : (
    <FileContextProvider>
      <header className="sticky-top">
        <div className="navbar navbar-expand bg-light navbar-light">
          <div className="container-fluid">
            <div className="d-flex align-items-center">
              <button
                onClick={() => setShowSidebar((pre) => !pre)}
                className="btn p-0 me-3"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <img
                height={25}
                alt="profile_image"
                className="rounded d-block d-sm-none"
                src={LogoIcon}
              />
              <Link href="/admin" className="navbar-brand fw-bold">
                <span className="d-none d-sm-inline">Sierraflixx </span>
                <span className="ms-2 ms-sm-0 fw-bolder text-primary">
                  Admin
                </span>
              </Link>
            </div>

            <ul className="nav navbar-nav mr-auto">
              <li className="nav-item dropdown">
                <a
                  data-bs-toggle="dropdown"
                  href="/admin/profile"
                  className="nav-link"
                >
                  <Image
                    width={30}
                    alt="profile_image"
                    className="rounded"
                    src={`${resPath}/${user?.img_url}`}
                  />
                </a>

                <div className="dropdown-menu dropdown-menu-end">
                  <div className="dropdown-header">
                    <small>Singed in as</small>
                    <p className="fw-bold m-0">{user?.full_name}</p>
                  </div>

                  <div className="dropdown-divider"></div>

                  <Link href="#profile" className="dropdown-item">
                    Your profile
                  </Link>

                  <Link href="#profile" className="dropdown-item">
                    Your notifications
                  </Link>

                  <div className="dropdown-divider"></div>

                  <Link
                    onClick={handleLogout}
                    href="/"
                    className="dropdown-item text-danger"
                  >
                    Sign out
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <main>
        <div className={`sidebar ${showSidebar ? "" : "hide-sidebar"}`}>
          <Sidebar current={current} />
        </div>

        <div className="container-fluid contents py-2 text-bg-white text-secondary">
          <Outlet />
        </div>
      </main>

      <ConfirmDelete />
      <ToastContainer />
      <FilePreview />
    </FileContextProvider>
  );
};

export default AdminPanel;
