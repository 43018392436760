import React, { useLayoutEffect, useState } from "react";
import { FormControl, Image, NavLink, Stack } from "react-bootstrap";
import { useFilePreview } from "../../imports/hooks";
import { ImagePlaceholder } from "../../imports/images";
import { resPath } from "../../utils/api";

const ImageSelect = (props) => {
  const [images, setImages] = useState([]);
  const [changed, setChange] = useState(false);

  function handleImageSelected({ target }) {
    if (target.files.length > 0) {
      const files = Object.keys(target.files).map((key) => {
        return URL.createObjectURL(target.files[key]);
      });

      setChange(true);
      setImages(files);
    } else {
      setImages(images);
    }
  }

  useLayoutEffect(() => {
    if (!changed) {
      if (props?.images) {
        setImages(props?.images?.map((img) => `${resPath}/${img}`));
      } else if (props?.src) {
        setImages([`${resPath}/${props?.src}`]);
      } else {
        setImages([ImagePlaceholder]);
      }
    }
  }, [props, changed]);

  return (
    <>
      <FormControl
        hidden
        type="file"
        accept="image/*"
        name={props?.name}
        multiple={props?.multiple}
        id={props?.id || props?.name}
        onChange={handleImageSelected}
      />

      <Stack
        direction="horizontal"
        gap={2}
        className="flex-wrap justify-content-center"
      >
        {images?.map &&
          images.map((img, index) => {
            return (
              <ImageContext
                key={`image_${index}`}
                {...props}
                image={img}
                browse={index < 1}
              />
            );
          })}
      </Stack>
    </>
  );
};

function ImageContext({ image, name, id, title, src, browse }) {
  const { dispatch } = useFilePreview();
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div
        className="position-relative text-center"
        style={{ maxWidth: "100%", width: "fit-content" }}
      >
        <Image
          alt="profile_image"
          className="rounded border p-1 image"
          src={image}
          width={96}
        />

        <label htmlFor={id || name} className="rounded image-label">
          <small>
            {image !== ImagePlaceholder ? "change" : "select"} {title}
          </small>
        </label>
      </div>
      {image !== ImagePlaceholder && (
        <NavLink
          onClick={() => {
            dispatch({
              type: "SHOW",
              payload: {
                path: image,
              },
            });
          }}
          className="text-center text-info"
          style={{ maxWidth: "fit-content" }}
        >
          <small>preview</small>
        </NavLink>
      )}
    </div>
  );
}

export default ImageSelect;
