import React from "react";
import { ListGroup } from "react-bootstrap";
import { API } from "../../imports/utils";
import Link from "./Link";
import Loader from "./Loader";

const Sidebar = ({ current = "dashboard" }) => {
  const { data: routes, isLoading } = API.Get({
    init: [],
    key: "authorised",
    path: "endpoints/authorised",
  });

  return !routes?.map || isLoading ? (
    <Loader fullScreen={false} />
  ) : (
    <ListGroup className="my-2 sidebar-nav rounded-0">
      {[
        { title: "Dashboard", url: "/dashboard", key: "dashboard" },
        ...routes,
      ].map((route, index) => {
        return (
          <ListGroup.Item
            action={true}
            key={`sidebar_route${index}`}
            className="rounded-0 p-0 text-bg-secondary border-0 text-white-50 text-truncate"
          >
            <Link
              active={current === `/${route.key}`}
              className="p-2"
              href={`/admin${route.url}`}
            >
              {route.title}
            </Link>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default Sidebar;
