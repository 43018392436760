import React, { useRef } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useAdmins, useConfirmDelete, useRoles } from "../../imports/hooks";
import { args } from "../../imports/utils";
import { resPath } from "../../utils/api";
import SubmitButton from "../custom/SubmitButton";

const AddEditAdmin = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { roles } = useRoles();
  const { dispatch } = useConfirmDelete();
  const { admin, show, hide } = { ...args.dialog, ...props };
  const { invite, update, processing, state, validated } = useAdmins({
    onSuccess,
    enabled: show,
    id: admin.id,
  });

  function onSuccess(res) {
    if (!admin?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        onHide: hide,
        path: "admins",
        key: "admins",
        value: { id: admin?.id, name: admin?.full_name },
      },
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {admin?.id ? "Edit" : "Invite"} Admin
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={admin?.id ? update : invite}
        >
          <Stack gap={3} className="mb-3">
            {!admin?.id ? (
              <>
                <Form.FloatingLabel label="Admin phone">
                  <Form.Control
                    name="phone"
                    type="number"
                    placeholder="Admin phone"
                    className="text-bg-light"
                    defaultValue={admin?.phone}
                    required
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide an admin title
                  </Form.Control.Feedback>
                </Form.FloatingLabel>

                <Form.FloatingLabel label="Admin role">
                  <Form.Control
                    name="role_id"
                    type="text"
                    as="select"
                    className="text-bg-light"
                    required
                    defaultValue={admin?.role?.id || ""}
                  >
                    <option disabled value="">
                      --- select ---
                    </option>
                    {roles?.map &&
                      roles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.title}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a role for this admin
                  </Form.Control.Feedback>
                </Form.FloatingLabel>
              </>
            ) : (
              <>
                <Row className="align-end border-bottom ">
                  <Col className="col-3">
                    <Image
                      alt="profile_image"
                      className="rounded img-fluid border p-1"
                      src={`${resPath}/${admin?.img_url}`}
                    />
                    <Stack>
                      <small className="text-muted">Joined</small>
                      <p className="mb-0">
                        {new Date(admin?.created_at).toLocaleDateString()}
                      </p>
                    </Stack>
                    {admin?.last_logged && (
                      <Stack>
                        <small className="text-muted">Last Seen</small>
                        <p className="mb-0">
                          {new Date(admin?.last_logged).toLocaleDateString()}
                        </p>
                      </Stack>
                    )}
                  </Col>
                  <Col>
                    <Stack className="border-bottom mb-2">
                      <small className="text-muted">Full Name</small>
                      <p className="mb-0">{admin?.full_name}</p>
                    </Stack>
                    <Stack className="border-bottom mb-2">
                      <small className="text-muted">Phone</small>
                      <p className="mb-0">{admin?.phone}</p>
                    </Stack>
                    <Stack className="border-bottom mb-2">
                      <small className="text-muted">Email</small>
                      <p className="mb-0">{admin?.email}</p>
                    </Stack>
                    <Stack className="mb-2">
                      <small className="text-muted">Role</small>
                      <p className="mb-0">{admin?.role}</p>
                    </Stack>
                  </Col>
                </Row>
                <Stack gap={2}>
                  <span className="fw-bold">Change Info</span>
                  <Form.FloatingLabel label="Change role">
                    <Form.Control
                      name="role_id"
                      type="text"
                      as="select"
                      className="text-bg-light"
                      defaultValue=""
                    >
                      <option disabled value="">
                        --- select ---
                      </option>
                      {roles?.map &&
                        roles.map((role) => {
                          return (
                            role.title !== admin?.role && (
                              <option key={role.id} value={role.id}>
                                {role.title}
                              </option>
                            )
                          );
                        })}
                    </Form.Control>
                  </Form.FloatingLabel>

                  <Form.FloatingLabel label="Change status">
                    <Form.Control
                      name="is_active"
                      type="text"
                      as="select"
                      className="text-bg-light"
                      defaultValue={admin?.id ? admin.is_active : ""}
                    >
                      <option disabled value="">
                        --- select ---
                      </option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </Form.Control>
                  </Form.FloatingLabel>

                  <Form.FloatingLabel label="Change phone">
                    <Form.Control
                      name="phone"
                      type="number"
                      placeholder="Change phone"
                      className="text-bg-light"
                      defaultValue={admin?.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide an admin title
                    </Form.Control.Feedback>
                  </Form.FloatingLabel>
                </Stack>
              </>
            )}

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {admin?.id ? "Update" : "Create"}
              </SubmitButton>

              {admin?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditAdmin;
