import React, { useLayoutEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import Slider from "react-slick";
import Player from "@gumlet/react-hls-player";
import { useFilePreview } from "../../imports/hooks";

const ImagePreview = () => {
  const { show, path, fileType, dispatch } = useFilePreview();
  const [files, setFiles] = useState([]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const hide = () => {
    dispatch({
      type: "HIDE",
    });
  };

  function getContent(file) {
    if (show) {
      switch (fileType) {
        case "video":
          return (
            <Player
              autoPlay={true}
              controls={true}
              preload="auto"
              src={file}
              height="auto"
              className="w-100 image"
            />
          );
        case "image":
        default:
          return <Image src={file} className="w-100 image" />;
      }
    }

    return null;
  }

  useLayoutEffect(() => {
    if (typeof path !== "string") {
      setFiles(path);
    } else if (path !== "/") {
      setFiles([path]);
    }
  }, [path]);

  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={hide}
      dialogClassName="bg-whit"
      backdropClassName="opacity-75 bg-secondary"
      contentClassName="border-0 shadow"
    >
      <Modal.Header closeButton className="border-0" />
      <Modal.Body className="text-center">
        <Slider {...settings}>
          {files.map((file, index) => {
            return (
              <div key={`file${index}`} className="position-relative img-cover">
                {getContent(file)}
              </div>
            );
          })}
        </Slider>
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreview;
