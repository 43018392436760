import React, { useRef } from "react";
import { Alert, Button, Form, Modal, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import { useConfirmDelete, useGenres } from "../../imports/hooks";
import SubmitButton from "../custom/SubmitButton";
import { args } from "../../imports/utils";

const AddEditGenre = (props = { ...args.dialog }) => {
  const ref = useRef();
  const { dispatch } = useConfirmDelete();
  const { genre, hide, show } = { ...args.dialog, ...props };

  const { create, update, state, processing, validated } = useGenres({
    onSuccess,
    id: genre?.id,
    enabled: show,
  });

  function onSuccess(res) {
    if (res?.success && !genre?.id) {
      toast.success(res?.message, { hideProgressBar: true });
      hide();
      return false;
    }

    return true;
  }

  function handleDelete() {
    dispatch({
      type: "SHOW",
      payload: {
        path: "genres",
        onHide: () => hide(),
        key: "genres",
        value: { id: genre?.id, name: genre?.title },
      },
    });
  }

  return (
    <Modal
      centered
      show={show}
      onHide={hide}
      contentClassName="text-bg-light border-0"
      backdropClassName="opacity-75 bg-secondary"
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="fw-bold">
          {genre?.id ? "Edit" : "Add"} Genre
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          ref={ref}
          noValidate
          onSubmit={genre?.id ? update : create}
          validated={validated}
        >
          <Stack gap={3} className="mb-3">
            <Form.FloatingLabel label="Type title">
              <Form.Control
                name="title"
                type="text"
                placeholder="Type title"
                className="text-bg-light"
                defaultValue={genre?.title}
                required
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                Please provide a type title
              </Form.Control.Feedback>
            </Form.FloatingLabel>

            <Form.FloatingLabel label="Type description">
              <Form.Control
                name="description"
                type="text"
                as="textarea"
                placeholder="Type description"
                className="text-bg-light"
                defaultValue={genre?.description}
              />
            </Form.FloatingLabel>

            {state && (
              <Alert variant={state?.success ? "success" : "danger"}>
                {state?.message}
              </Alert>
            )}
          </Stack>

          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <Stack direction="horizontal" gap={3}>
              <SubmitButton
                isProcessing={processing}
                size="md"
                className="rounded"
              >
                {genre?.id ? "Update" : "Create"}
              </SubmitButton>

              {genre?.id && (
                <Button
                  onClick={handleDelete}
                  variant="outline-danger"
                  type="button"
                  className="letter-spaced fw-bolder"
                >
                  Delete
                </Button>
              )}
            </Stack>

            <Button
              onClick={hide}
              variant="light"
              type="button"
              className="letter-spaced fw-bolder border"
            >
              Close
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditGenre;
